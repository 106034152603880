import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { Link } from 'react-router-dom'
import EduTemp from './EduTemp'

export default function Educational() {
  const [questions, setQuestions] = useState([]);
  const PubertyQuestions = [
    {
      Title: "Pathophysiology of delayed and precocious puberty",
      Link: "https://www.ncbi.nlm.nih.gov/books/NBK534827/",
    },
    {
      Title: "Stages for boys and girls",
      Link: "https://my.clevelandclinic.org/health/articles/22192-puberty",
    },
    {
      Title: "Puberty resources for parents",
      Link: "https://www.newcastle.gov.uk/sites/default/files/Puberty%20resource%20for%20parents%20May%202020pdf.pdf",
    },
    {
      Title: "About puberty",
      Link: "https://www.betterhealth.vic.gov.au/health/healthyliving/puberty",
    },
    {
      Title: "Puberty",
      Link: "https://medlineplus.gov/puberty.html",
    },
    {
      Title: "Tanner stages of puberty and other signs and symptoms of puberty",
      Link: "https://www.healthline.com/health/parenting/stages-of-puberty#other-signs-of-puberty",
    },
  ];
  const MenstruationResources = [
    {
      Title: "Menstruation",
      Link: "https://medlineplus.gov/menstruation.html",
    },
    {
      Title: "Menstrual cycle",
      Link: "https://my.clevelandclinic.org/health/articles/10132-menstrual-cycle",
    },
    {
      Title: "Things you didn't know about menstruation",
      Link: "https://www.unicef.org/press-releases/fast-facts-nine-things-you-didnt-know-about-menstruation",
    },
    {
      Title: "Common menstrual problems",
      Link: "https://www.betterhealth.vic.gov.au/health/conditionsandtreatments/menstrual-cycle#common-menstrual-problems",
    },
    {
      Title: "Menstruation: Science and society",
      Link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7661839/",
    },
    {
      Title: "Your first period",
      Link: "https://www.acog.org/womens-health/faqs/your-first-period",
    },
    {
      Title: "Menstrual hygiene",
      Link: "https://www.cdc.gov/hygiene/personal-hygiene/menstrual.html",
    },
    {
      Title: "Heavy menstrual bleeding",
      Link: "https://www.cdc.gov/ncbddd/blooddisorders/women/menorrhagia.html#:~:text=If%20you%20have%20bleeding%20that,medical%20history%20and%20menstrual%20cycles.",
    },
    {
      Title: "Period problems: What they mean and when to see a doctor",
      Link: "https://www.webmd.com/women/features/period-problems-what-they-mean-when-to-see-doctor",
    },
    {
      Title: "Period protocol: When is it time to call a doctor?",
      Link: "https://www.texashealth.org/Health-and-Wellness/Women-and-Infants/Period-Protocol-When-Is-It-Time-to-Call-Your-Doctor",
    },
    {
      Title: "How menstruation affects women's health",
      Link: "https://ki.se/en/research/how-menstruation-affects-womens-health",
    },
    {
      Title: "Period products: What are the options",
      Link: "https://www.ippf.org/blogs/period-products-what-are-options",
    },
    {
      Title: "How to choose period products",
      Link: "https://www.health.harvard.edu/blog/how-to-choose-period-products-202210052828",
    },
    {
      Title: "Guide to menstrual hygiene materials",
      Link: "https://www.unicef.org/media/91346/file/UNICEF-Guide-menstrual-hygiene-materials-2019.pdf",
    },
    {
      Title: "Menstrual hygiene",
      Link: "https://www.unicef.org/wash/menstrual-hygiene",
    },
    {
      Title: "Menstruation and human rights-FAQs",
      Link: "https://www.unfpa.org/menstruationfaq",
    },
    {
      Title: "Sustainable menstruation",
      Link: "https://www.csusm.edu/sustainability/resources/sustainablemenstruation.html",
    },
  ];
  const ConsentResources = [
    {
      Title: "Helplines - National Commission for Women",
      Link: "http://www.ncw.nic.in/helplines",
    },
    {
      Title: "Consent - Wikipedia",
      Link: "https://en.m.wikipedia.org/wiki/Consent",
    },
    {
      Title: "What Is Consent? - UCR CARE Program - University of California, Riverside",
      Link: "https://care.ucr.edu/education/what-is-consent",
    },
    {
      Title: "GUIDELINES & PROTOCOLS",
      Link: "https://main.mohfw.gov.in/sites/default/files/953522324.pdf",
    },
    {
      Title: "The impact of sexual abuse on female development - NCBI",
      Link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3693773/",
    },
    {
      Title: "Legal Role of Consent - RAINN",
      Link: "https://www.rainn.org/articles/legal-role-consent",
    },
    {
      Title: "Understanding Consent - American Sexual Health Association",
      Link: "https://www.ashasexualhealth.org/understanding-consent/",
    },
    {
      Title: "Sexual violence against women: Understanding cross-cultural",
      Link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3777345/",
    },
    {
      Title: "Preventing and Responding to Sexual Exploitation, Abuse and Harassment - WHO",
      Link: "https://www.who.int/initiatives/preventing-and-responding-to-sexual-exploitation-abuse-and-harassment",
    },
  ];
  
  // Use the EducationalResources array as needed in your JavaScript code.
  
  const ContraceptiveResources = [
    {
      Title: "Oral Contraceptive Pills - National Centre for Biotechnology Information",
      Link: "https://www.ncbi.nlm.nih.gov/books/NBK430882/",
    },
    {
      Title: "Family Planning - World Health Organization (WHO)",
      Link: "https://www.who.int/news-room/fact-sheets/detail/family-planning-contraception",
    },
    {
      Title: "Contraception - Centers for Disease Control and Prevention (CDC)",
      Link: "https://www.cdc.gov/reproductivehealth/contraception/index.htm",
    },
    {
      Title: "Birth Control Pills - Cleveland Clinic",
      Link: "https://my.clevelandclinic.org/health/treatments/3977-birth-control-the-pill",
    },
    {
      Title: "Combined Pills - NHS (National Health Service)",
      Link: "https://www.nhs.uk/conditions/contraception/combined-contraceptive-pill/",
    },
    {
      Title: "Oral Contraceptive Pills - U.S. Food and Drug Administration (FDA)",
      Link: "https://www.fda.gov/news-events/press-announcements/fda-approves-first-nonprescription-daily-oral-contraceptive",
    },
    {
      Title: "Contraception - Combined Pills - Better Health Victoria",
      Link: "https://www.betterhealth.vic.gov.au/health/healthyliving/contraception-the-pill",
    },
  ];
  
  // Use the ContraceptiveResources array as needed in your JavaScript code.
  const STDResources = [
    {
      Title: "Sexually Transmitted Diseases - MedlinePlus",
      Link: "https://medlineplus.gov/sexuallytransmitteddiseases.html",
    },
    {
      Title: "Center for Disease Control - STDs",
      Link: "https://www.cdc.gov/std/default.htm",
    },
    {
      Title: "Sexually Transmitted Infections - World Health Organization (WHO)",
      Link: "https://www.who.int/news-room/fact-sheets/detail/sexually-transmitted-infections-(stis)",
    },
    {
      Title: "Sexually Transmitted Infections - National Institute of Allergy and Infectious Diseases (NIAID)",
      Link: "https://www.niaid.nih.gov/diseases-conditions/sexually-transmitted-infections",
    },
    {
      Title: "STIs - NHS (National Health Service)",
      Link: "https://www.nhs.uk/conditions/sexually-transmitted-infections-stis/",
    },
    {
      Title: "Sexually Transmitted Diseases - Johns Hopkins Medicine",
      Link: "https://www.hopkinsmedicine.org/health/conditions-and-diseases/sexually-transmitted-diseases",
    },
  ];
  
  // Use the STDResources array as needed in your JavaScript code.
  const PregnancyAndAbortionResources = [
    {
      Title: "About Pregnancy - National Institute of Child Health and Human Development (NICHD)",
      Link: "https://www.nichd.nih.gov/health/topics/pregnancy/conditioninfo#:~:text=Pregnancy%20is%20the%20term%20used,segments%20of%20pregnancy%2C%20called%20trimesters.",
    },
    {
      Title: "Symptoms of Pregnancy - Healthline",
      Link: "https://www.healthline.com/health/pregnancy#symptoms",
    },
    {
      Title: "Calculating Pregnancy Duration and Pregnancy Week by Week - Better Health Victoria",
      Link: "https://www.betterhealth.vic.gov.au/health/healthyliving/pregnancy-week-by-week",
    },
    {
      Title: "Description, Symptoms, and Stages of Pregnancy - Britannica",
      Link: "https://www.britannica.com/science/pregnancy",
    },
    {
      Title: "Pregnancy Milestones: First Trimester - UNICEF",
      Link: "https://www.unicef.org/parenting/pregnancy-milestones/first-trimester",
    },
    {
      Title: "How Your Fetus Grows During Pregnancy - American College of Obstetricians and Gynecologists (ACOG)",
      Link: "https://www.acog.org/womens-health/faqs/how-your-fetus-grows-during-pregnancy",
    },
    {
      Title: "Finding Out You're Pregnant - NHS",
      Link: "https://www.nhs.uk/pregnancy/finding-out/",
    },
    {
      Title: "Common Pregnancy Complications - Johns Hopkins Medicine",
      Link: "https://www.hopkinsmedicine.org/health/conditions-and-diseases/staying-healthy-during-pregnancy/4-common-pregnancy-complications",
    },
    {
      Title: "Key Facts About Abortion - World Health Organization (WHO)",
      Link: "https://www.who.int/news-room/fact-sheets/detail/abortion",
    },
    {
      Title: "Judgement of Supreme Court on Abortion - NCBI",
      Link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC10321178/",
    },
    {
      Title: "Guidelines for Medical Abortion in India - AIIMS",
      Link: "https://aiims.edu/aiims/events/Gynaewebsite/ma_finalsite/report/2_1_7.html",
    },
    {
      Title: "Everything You Need to Know About Abortion in India - Federation of Obstetric and Gynaecological Societies of India (FOGSI)",
      Link: "https://www.fogsi.org/wp-content/uploads/committee-2020-activities/issue-3-consent-november-2019.pdf",
    },
  ];
  const SexualHealthResources = [
    {
      Title: "What's Oral and Anal Sex? - Planned Parenthood",
      Link: "https://www.plannedparenthood.org/learn/teens/sex/all-about-sex",
    },
    {
      Title: "Sexual Intercourse - Britannica",
      Link: "https://www.britannica.com/science/sexual-intercourse",
    },
    {
      Title: "How Long Does Good Sexual Intercourse Last? - Penn State University",
      Link: "https://www.psu.edu/news/research/story/good-sexual-intercourse-lasts-minutes-not-hours-therapists-say/",
    },
    {
      Title: "Asexuality, Attraction, and Romantic Orientation - UNC LGBTQ Center",
      Link: "https://lgbtq.unc.edu/resources/exploring-identities/asexuality-attraction-and-romantic-orientation/",
    },
    {
      Title: "What Is Asexual? - WebMD",
      Link: "https://www.webmd.com/sex/what-is-asexual",
    },
    {
      Title: "Compulsive Sexual Behavior - Mayo Clinic",
      Link: "https://www.mayoclinic.org/diseases-conditions/compulsive-sexual-behavior/symptoms-causes/syc-20360434",
    },
    {
      Title: "Sex Addiction, Hypersexuality, and Compulsive Sexual Behavior - Cleveland Clinic",
      Link: "https://my.clevelandclinic.org/health/diseases/22690-sex-addiction-hypersexuality-and-compulsive-sexual-behavior",
    },
    {
      Title: "Good Hygiene After Sex - WebMD",
      Link: "https://www.webmd.com/sex-relationships/ss/slideshow-sexual-hygiene",
    },
    {
      Title: "What Does It Mean to Lose Your Virginity? - Medical News Today",
      Link: "https://www.medicalnewstoday.com/articles/326122",
    },
    {
      Title: "Why Does Sex Hurt? - NHS",
      Link: "https://www.nhs.uk/common-health-questions/sexual-health/why-does-sex-hurt/#:~:text=Women%20can%20experience%20pain%20during,can%20make%20your%20vagina%20dry",
    },
    {
      Title: "Vaginismus - NHS",
      Link: "https://www.nhs.uk/conditions/vaginismus/#:~:text=What%20is%20vaginismus%3F,have%20no%20control%20over%20it",
    },
    {
      Title: "Premature Ejaculation - Urology Care Foundation",
      Link: "https://www.urologyhealth.org/urology-a-z/e/erectile-dysfunction-(ed)",
    },
    {
      Title: "Masturbation: Facts and Benefits - Cleveland Clinic",
      Link: "https://my.clevelandclinic.org/health/articles/24332-masturbation",
    },
    {
      Title: "What to Know About Sex During Pregnancy - Medical News Today",
      Link: "https://www.medicalnewstoday.com/articles/321648",
    },
  ];
  
  
  const updateList = () => {
    if(localStorage.getItem('topic')==='Puberty & Changes')setQuestions(PubertyQuestions);
    if(localStorage.getItem('topic')==='Menstruation & Myths')setQuestions(MenstruationResources);
    if(localStorage.getItem('topic')==='Pregnancy & Abortion')setQuestions(PregnancyAndAbortionResources);
    if(localStorage.getItem('topic')==='Intercourse')setQuestions(SexualHealthResources);
    if(localStorage.getItem('topic')==='STD & symptoms')setQuestions(STDResources);
    if(localStorage.getItem('topic')==='Contraceptives')setQuestions(ContraceptiveResources);
    if(localStorage.getItem('topic')==='Consent & sexual health')setQuestions(ConsentResources);
  };

  useEffect(() => {
    updateList();
  }, []);
  return (
    <div>
        <Header/>
        <div className="headermen my-3">
        <h1>Educational Resources</h1>
      </div>
      {questions.map((question, i) => {
          return (
            <EduTemp
              title={question.Title}
              id={i}
              link={question.Link}
              index={i}
            />
          );
        })}
    
    </div>
  )
}

