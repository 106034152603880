import React from 'react'
import { useRef, useState } from "react";
import {
  makeSchedule,

} from '../../firebase';
import Time from "./../Time";
import Calendar from "react-calendar";
import Calender from "../../Calender";
import { FirebaseError } from 'firebase/app';
import './MakeSchedule.css'
export default function MakeSchedule({ refOpen, refClose }) {

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedTime, setSelectedTime] = useState("30:00");
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [edit, setedit] = useState("");

  const toggleTimeSelection = (time) => {
    if (selectedTimes.includes(time)) {
      setSelectedTimes(selectedTimes.filter(t => t !== time));
    } else {
      setSelectedTimes([...selectedTimes, time]);
    }

  };
  const isSelected = (time) => selectedTimes.includes(time);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleSchedule = async () => {
    if (edit !== "") {
      selectedTimes.push(edit);
    }
    for (let index = 0; index < selectedTimes.length; index++) {
      let value = selectedTimes[index]
      const res = await makeSchedule(
        localStorage.getItem("userId"),
        localStorage.getItem("type"),
        { selectedDate, selectedTime: value }
      );


    }
    alert("Appointment Scheduled");
    // setSelectedTime("12:00");
    window.location.reload(false);

  };

  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        ref={refOpen}
        hidden={true}
        data-bs-toggle="modal"
        data-bs-target="#schedule"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="schedule"
        tabindex="-1"
        ref={refClose}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel" style={{ fontWeight: "bold", fontSize: "24px;" }}>
                Make Your Schedule
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
             
              {/* <DatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  minDate={new Date()}
                  maxDate={new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)}
                /> */}
              <Calender setSelectedDate={setSelectedDate} />
              {/* <TimePicker value={selectedTime} onChange={handleTimeChange} /> */}
              <div className='boxestime'>
                <button onClick={() => { toggleTimeSelection("09:00") }} style={{ backgroundColor: isSelected("09:00") ? 'rgb(80, 144, 239)' : 'grey' }}>09:00</button>
                <button onClick={() => { toggleTimeSelection("10:00") }} style={{ backgroundColor: isSelected("10:00") ? 'rgb(80, 144, 239)' : 'grey' }}>10:00</button>
                <button onClick={() => { toggleTimeSelection("11:00") }} style={{ backgroundColor: isSelected("11:00") ? 'rgb(80, 144, 239)' : 'grey' }}>11:00</button>
                <button onClick={() => { toggleTimeSelection("12:00") }} style={{ backgroundColor: isSelected("12:00") ? 'rgb(80, 144, 239)' : 'grey' }}>12:00</button>
              </div>
              <div className='boxestime'>
                <button onClick={() => { toggleTimeSelection("13:00") }} style={{ backgroundColor: isSelected("13:00") ? 'rgb(80, 144, 239)' : 'grey' }} >13:00</button>
                <button onClick={() => { toggleTimeSelection("14:00") }} style={{ backgroundColor: isSelected("14:00") ? 'rgb(80, 144, 239)' : 'grey' }} >14:00</button>
                <button onClick={() => { toggleTimeSelection("15:00") }} style={{ backgroundColor: isSelected("15:00") ? 'rgb(80, 144, 239)' : 'grey' }} >15:00</button>
                <button onClick={() => { toggleTimeSelection("16:00") }} style={{ backgroundColor: isSelected("16:00") ? 'rgb(80, 144, 239)' : 'grey' }} >16:00</button>

              </div>

              <Time selectedTime={selectedTime} allTimes={selectedTimes} setSelectedTime={setSelectedTime} setedit={toggleTimeSelection} />
   

            </div>
            <div class="modal-footer">
              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSchedule}
              >
                Confirm Schedule
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}