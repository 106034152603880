import React, { useState, useRef, useEffect } from 'react';
import './ChatEnd.css';
import{
    updateQueryState,
    updateReportedState
}from './firebase'
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const ChatEnd = (props) => {
   
    const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [report,setReport]=useState("");
  const refOpen = useRef(null);
  const refReportClose = useRef(null);
  
  const menuRef = useRef(null);
  const handleEndChat = async () => {
    // // console.log("SignOut")
    // console.log("Deleyting",props.id)
    await updateQueryState(props.id);
    localStorage.removeItem("queries")
    alert("Chat Ended Succesfully")
    if(localStorage.getItem('userType')==='1') navigate('/queries')
    else navigate('/')
    
  };
  const handleReportChat = async () => {
    await updateReportedState(props.id,report,localStorage.getItem('type'))
    alert("Chat Reported Succesfully")
    localStorage.removeItem("queries")
    refReportClose.current.click();
   if(localStorage.getItem('userType')==='1') navigate('/queries')
   else navigate('/')
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleConfirm = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };



  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsConfirmOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <>
   
<button type="button" class="btn btn-primary" hidden={true} ref={refOpen} data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Report Query</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" ref={refReportClose} aria-label="Close"></button>
      </div>
      <div class="modal-body reason1">
        <label className='mx-2' htmlFor="">Reason:</label>
        <input value={report} onChange={(e)=>setReport(e.target.value)} type='text'className='awse' placeholder='Enter reason'/>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" onClick={handleReportChat}>Submit Report</button>
      </div>
    </div>
  </div>
</div>


    <div className="left-content" ref={menuRef}>
      <div className={` menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>

        {<i class="fa-solid fa-ellipsis" style={{ color: 'white', fontSize: '25px', marginTop: '10px' }}></i>}
      </div>
      {isMenuOpen && (
        <div className="menu-popup2">
          <h1 style={{ fontSize: '25px', textAlign: 'center', marginBottom: '20px' }}>Select Option</h1>
         
            <button className="ekambutton" onClick={()=>  refOpen.current.click()}>
              Report
            </button>
       
          <br />
          <button className="ekambutton my-1" onClick={toggleConfirm}>
            End Chat
          </button>
        </div>
      )}

      {isConfirmOpen && (
        <div className="menu-popup2" style={{ paddingBottom: '35px' }}>
          <h1 style={{ fontSize: '25px', marginBottom: '40px' }}>Confirm to End Query?</h1>
          <button className="ekambutton mx-2" onClick={handleEndChat}>
            Yes
          </button>

          <button className="ekambutton" onClick={() => { toggleConfirm(); toggleMenu(); }}>
            No
          </button>
        </div>
      )}
    </div>
    </>
  );
};

export default ChatEnd;
