import React, { useEffect, useState} from 'react';
import './query.css'
import Header from '../Header';
import { GetQueryForuser, storeQuery,GetPrevQueryForuser } from '../firebase';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import Modal2 from '../Prof/Modal2';

import { useRef } from 'react';

const  AskQuery = () => {
const navigate=useNavigate();
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');

  const handlePost = async () => {
    
   await storeQuery(title,description, localStorage.getItem('userId'))
   alert("QUERY Posted");
   getQueries();
    setTitle("")
    setDescription('')
    
  };

const refOpen = useRef(null);
const refOpen2 = useRef(null);
const refClose = useRef(null);
const refClose2 = useRef(null);
const usertype=localStorage.getItem("type")

const [queries,setQueries]=useState([]);
const [previousqueries,setpreviousQueries]=useState([]);

const getQueries=async()=>{
  // console.log("CAll ME")
   const res=await GetQueryForuser(localStorage.getItem('userId'));
  localStorage.setItem('queries', JSON.stringify(res));
   setQueries(res);
}
const getpreviousQueries=async()=>{
   const res=await GetPrevQueryForuser(localStorage.getItem('userId'));
   setpreviousQueries(res);
}
const checkQueries=()=>{
  if(!localStorage.getItem('queries'))getQueries();
  else{
    setQueries(JSON.parse(localStorage.getItem('queries')));
  }
}
useEffect(()=>{
  refClose.current.click();
  getpreviousQueries();
},[])
  return (
    <div style={{paddingBottom:'40px'}}>
    <Header/>
    <div className='QueryHeading'>
      <label style={{alignText:"center",marginLeft:"20px"}}>Welcome To Query Box</label>
      </div>
    <div className="questionSection">
      <Modal refOpen={refOpen} refClose={refClose} queries={queries} usertype={usertype}/>
      <Modal2 title={"Previous Queries"} refOpen={refOpen2} refClose={refClose2}  queries={previousqueries} usertype={usertype}  />
      <div className='input-container'>
        <label className="Title-label">What is your Query Related to?</label>
     
       <input
          type="text"
          id="title"
          class="form-control"
          value={title}
          placeholder=" Describe in few words"
          onChange={(e) => setTitle(e.target.value)}
          className="title-input"
        />
    
      </div>
      <hr />
      <label className='abstract-label'>Type your Question Here</label>
      <textarea
        id="problem"
        className="abstract-input"
        placeholder='Enter a 1-paragraph description'
        rows="7" 
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      </div>
      <div className='d-flex justify-content-end'>
      <button disabled={title===""||description===""} className="post-button1" onClick={handlePost} >Post</button>
      </div>

      <div className='d-flex justify-content-center'>
      <button className='post-button mx-2' onClick={()=>{refOpen.current.click();checkQueries()}}  >Ongoing Queries</button>
      <button className='post-button' onClick={()=>{refOpen2.current.click()}}  >Previous Queries</button>
      </div>
      </div>
  );
};

export default  AskQuery;
