import React from 'react'
import logo from "../Images/logo.png";
import Header from './Header';
import './AboutUs.css';
export default function AboutUS() {
  return (
      <>
        <div style={{backgroundColor:'azure'}}>
        <Header/>

        <h1  className='container d-flex justify-content-center' style={{color:'navy-blue',fontSize:'50px',marginBottom:'40px',marginTop:'20px',fontWeight:"bold"}}>About Us</h1>
        <div className='mainabout' >
            <img  class='aboutimg' src={logo} alt="" />
            
            <div className='col2nd' style={{marginTop:'50px'}}>

            
            <h1  className='container d-flex justify-content-center' style={{color:'rgb(14, 14, 88)',fontSize:'50px',fontWeight:"bold"}}>unStigma
            
            </h1>
        <p className='container d-flex justify-content-right vansh' style={{color:'black',textAlign: "justify"}}>
        We, at unStigma, endeavour to foster age-appropriate awareness,addressing taboo questions all the while maintaining privacy from the comfort of a phone. Our vision is a future where open conversations about 
        Sexual and Reproductive Health unite generations through the creation of a 
        user-friendly unStigma.
        </p>
        </div>
        </div>
       
        </div>
    </>
  )
}
