import "./Appointmentprof.css";
import React, { useRef, useState } from "react";
import "./../queries.css";
import { useEffect } from "react";
import EditSchedule from "./EditSchedule";
import {
  GetQueryForAllProf,
  GetQueryForProf,
  acceptQuery,
  makeSchedule,
  GetUpcomingAppointment,
  GetScheduledAppointment,
  GetConfirmedAppointment,
  GetPreviousAppointment,
} from "../../firebase";
import MakeSchedule from "./MakeSchedule";
import { useNavigate } from "react-router-dom";
import Modal from "../../User/Modal";
import DatePicker from "react-datepicker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
// import { ScrollShadow } from "@nextui -org/react";
import Accordion from "./../Accordion";
import Time from "./../Time";
import Calendar from "react-calendar";
import Calender from "../../Calender";
import YourSchedule from "./YourSchedule";
import UpdateTime from "./UpdateTime";
import ConfirmedApointment from "./confirmedAppointment";
import PreviousAppointments from "./PreviousAppointments";

//npm install react-datetime-picker
function Appointmentprof() {
  const navigate = useNavigate();
  const data = [1, 2, 3, 4, 5, 6, 7];
  const [Data, setData] = useState([]);
  const refEditOpen = useRef(null);
  const refEditClose = useRef(null);

  const refOpenUpdate = useRef(null);
  const refCloseUpdate = useRef(null);

  const refopenyourschedule = useRef(null);
  const refCloseyourSchedule = useRef(null);

  const refOpenMake = useRef(null);
  const refCloseMake = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [appointments, setappointments] = useState([]);

  const [change, setchange] = useState("1");

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setScrollPosition(scrollTop);
  };
  const getallAppointment = async () => {
    const res = await GetUpcomingAppointment(localStorage.getItem("userId"));

    sortData(res);
    //  // console.log("Result",res)
  };
  const getPreviousAppointments = async () => {
    const res = await GetPreviousAppointment(localStorage.getItem("userId"));

    console.log("data", res);
    sortData(res);
    //  // console.log("Result",res)
  };
  const doctorid = localStorage.getItem("userId");
  const sortData = (res) => {
    let sorted = res.sort((a, b) => {
      let dateA = new Date(`${a.Date} ${a.Time}`).getTime();
      let dateB = new Date(`${b.Date} ${b.Time}`).getTime();

      return dateA - dateB;
    });
    setappointments(sorted);
    // console.log("Sorted", sorted);
  };

  useEffect(() => {
    getallAppointment();
  }, []);
  // // console.log("DTAATATAT", Data)
  return (    
    <div className="Ap">
      <div>
        <div className="butn">
          {change === "1" ? (
            <div>
              <button
                className="upcoming"
                style={{ backgroundColor: "blue" }}
                onClick={() => setchange("1")}
              >   
                {" "}
                Appointment Requests
              </button>
              <button className="upcoming" onClick={() => setchange("2")}>
                Confirmed Appointments
              </button>
              <button
                className="upcoming"
                onClick={() => {
                  setchange("3");
                  getPreviousAppointments();
                }}
              >
                Previous Appointments
              </button>
            </div>
          ) : (
            ""
          )}
          {change === "2" ? (
            <div>
              <button className="upcoming" onClick={() => setchange("1")}>
                {" "}
                Appointment Requests
              </button>
              <button
                className="upcoming"
                style={{ backgroundColor: "blue" }}
                onClick={() => setchange("2")}
              >
                Confirmed Appointments
              </button>
              <button
                className="upcoming"
                onClick={() => {
                  setchange("3");
                  getPreviousAppointments();
                }}
              >
                Previous Appointments
              </button>
            </div>
          ) : (
            ""
          )}
          {change === "3" ? (
            <div>
              <button className="upcoming" onClick={() => setchange("1")}>
                {" "}
                Appointment Requests
              </button>
              <button className="upcoming" onClick={() => setchange("2")}>
                Confirmed Appointments
              </button>
              <button
                className="upcoming"
                style={{ backgroundColor: "blue" }}
                onClick={() => {
                  setchange("3");
                  getPreviousAppointments();
                }}
              >
                Previous Appointments
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="appointments">
          {/* <h1>Appointments</h1> */}

          <div className="d-flex  justify-content-center">
            {change === "1" ? (
              <div
                className="scroll-container"
                style={{
                  width: "600px",
                  height: "330px",
                  backgroundColor: "#0073a1",
                  borderRadius: "8px",
                }}
                onScroll={handleScroll}
              >
                <div className="content">
                  <h1 style={{ color: "white" }}> Appointments Requests</h1>
                  <div
                    className="contain"
                    style={{ border: "2px solid #0073a1" }}
                  >
                    <div class="timelinecontainer">
                      <div
                        style={{ width: "100%", backgroundColor: "#0073a1" }}
                      >
                        <div class="accordion " id="accordionFlushExample">
                          {appointments.length === 0
                            ? "No Requests"
                            : appointments.map((item, i) => {
                                // console.log("DAtaataa", item)
                                return <Accordion value={i} data={item} />;
                              })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {change === "2" ? <ConfirmedApointment /> : null}
            {change === "3" ? <PreviousAppointments /> : null}
          </div>

          <button
            className=" my-2 btn mx-3 btn-primary"
            onClick={() => refopenyourschedule.current.click()}
          >
            Your Schedule
          </button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
          {/* 
          <button className=" my-2 btn mx-3 btn-primary" onClick={() => navigate("/confirmed")}>
            Confirmed Appointments
          </button> */}
        </div>
      </div>

      <YourSchedule
        refEditOpen={refEditOpen}
        refOpenMake={refOpenMake}
        refCloseMake={refCloseMake}
        refopenyourschedule={refopenyourschedule}
        refCloseyourSchedule={refCloseyourSchedule}
      />
      <MakeSchedule refOpen={refOpenMake} refClose={refCloseMake} />
      <EditSchedule
        setData={setData}
        refOpen={refEditOpen}
        refClose={refEditClose}
        refOpenUpdate={refOpenUpdate}
      />
      <UpdateTime
        Data={Data}
        refOpen={refOpenUpdate}
        refClose={refCloseUpdate}
      />
      <div className="appointment-details"></div>
    </div>
  );
}

export default Appointmentprof;
