import React, { useState } from "react";
import { render } from "react-dom";
import Calendar from "react-calendar";
import './Calender.css'
export default function Calender(props) {
    const [value, onChange] = useState(new Date());
    // // console.log('val',value)
    const year = value.getFullYear();
// Months are zero-based, so we add 1 to get the correct month
const month = String(value.getMonth() + 1).padStart(2, "0");
const day = String(value.getDate()).padStart(2, "0");

// Form the "yy-mm-dd" string
const formattedDate = `${year}-${month}-${day}`;
props.setSelectedDate(formattedDate)

// // console.log(formattedDate); // Output: 2023-12-17
    return (
      <div className="d-flex justify-content-center">
        <Calendar minDate={new Date()}   onChange={onChange} value={value} />
      </div>
    );
}
