import React from 'react';
import './Guidelines.css'; // Import the CSS file
import Header from '../Header';

const GuidelinesUser = () => {
  return (
    <>
    <Header/>
    <div className="guidelines-container" style={{marginLeft: '20px'}}>
      <h1 className="main-heading">Guidelines for using unStigma</h1>

      <section className="guideline-section">
        <h3>Respectful Communication:</h3>
        <p>
        Users are expected to engage in respectful and considerate communication within any interactive features of the app. Harassment, hate speech, or inappropriate behavior will not be tolerated.

        </p>
      </section>

      <section className="guideline-section">
        <h3>Accurate Information:</h3>
        <p>
        Users should use the app responsibly and seek accurate information. The app provides educational content, but users are encouraged to consult healthcare professionals within the app for personalized advice.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Emergency Situations:</h3>
        <p>
        In case of emergencies or urgent health concerns, users should contact appropriate healthcare professionals or emergency services. The app is not a substitute for immediate medical attention.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Informed Decision-Making:</h3>
        <p>
        Users are encouraged to make informed decisions about their sexual and reproductive health. The app provides information, but users should consult with healthcare providers for personalized advice.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Anonymity and Confidentiality in Query Box:</h3>
        <p>
        Users utilizing the anonymous question feature should ensure that the questions do not reveal personally identifiable information. The app will maintain the anonymity of users to the extent possible.



        </p>
      </section>

      {/* Add more sections for other guidelines... */}

      <h1 className="main-heading">Terms and Conditions </h1>

      <section className="guideline-section">
        <h3>Acceptance of Terms:</h3>
        <p>
        By using the app, users agree to comply with these guidelines and terms and conditions. If users do not agree, they should refrain from using the app.

        </p>
      </section>

      <section className="guideline-section">
        <h3>Limitation of Liability:</h3>
        <p>
        The app owner is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of the app. Users use the app at their own risk.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Appropriate Use:</h3>
        <p>
        Users agree to use the app for lawful and appropriate purposes. Any use that violates local, national, or international laws is strictly prohibited.


        </p>
      </section>
      <section className="guideline-section">
        <h3>Changes to Terms:</h3>
        <p>
        The app owner reserves the right to update or modify these terms and conditions. Users will be notified of any significant changes, and continued use of the app implies acceptance of the revised terms.


        </p>
      </section>
      <section className="guideline-section">
        <h3>Termination of Access:</h3>
        <p>
        The app owner reserves the right to terminate a user's access to the app if they violate these terms and conditions or engage in inappropriate behavior within the app.


        </p>
      </section>
     
      <section className="guideline-section">
        <h3>Governing Law:</h3>
        <p>
        These terms and conditions are governed by the laws of the jurisdiction where the app owner is based. Any disputes arising from the use of the app will be resolved in accordance with these laws.
        </p>
      </section>

      {/* Add more sections for other terms and conditions... */}
    </div>
    </>
  );
};

export default GuidelinesUser;
