import React, { useState } from "react";

import { useNavigate,Link } from "react-router-dom";
import Login from "./Login";
import { app, auth } from "./firebase";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import icons from "../Images/logo new.png";
import "./HomeLogin.css";

export default function HomeLogin(props) {
  const { isLogged } = props;

  const navigate = useNavigate();
  const navigateToLogin = (e) => {
    props.setuserType(e);
    localStorage.setItem('loginas',e);
    // // console.log(e);
    navigate("/login");
  };
  const handleLogout = () => {
    auth.signOut();
    props.showAlert("LoggedOut Successfully", "success");
  };
  const posthandler = () => {
    navigate("/postpage");
  };

  return (
    <div>
        <div className="header">
          <h1 style={{color:'navy',fontWeight:"600"}} >unStigma</h1>
          <h4 >Answering the questions you couldn't ask</h4>
          {/* <h4>Unstigma</h4> */}
          {/* <div className="logo"><img width='50px' height='50px' src='https://th.bing.com/th/id/OIP.jSBstnLuHMSDm5JiMs21YAHaHa?pid=ImgDet&w=600&h=600&rs=1'></img></div> */}
        </div>
        <img className="stigma" src={icons} alt="" />
      <div
        className="containe"
      
      >

        {/* <h3 id='title'>UNSTRIGMA</h3> */}
        {/* <input type="text" className="form-control mx-3 md-3 " id='searchinput' placeholder="&#x1F50E;&#xFE0E;Search..."/>  */}
        <div className="buttonss">

        {isLogged ? (
            <button
            id="postbutton"
            onClick={(e) => {
                navigate("/subscribe");
            }}
            >
            Plans
          </button>
        ) : (
            ""
            )}
        <div className="mx-3">
          {isLogged ? (
              <button
              className="buttonclick"
              id="loginbutton"
              onClick={(e) => handleLogout()}
            >
              LogOut
            </button>
          ) : (
              <button
              className="buttonclick"
              id="loginbutton"
              onClick={(e) => navigateToLogin(1)}
              >
              Login as a User
            </button>
          )}
        </div>
        <div className="mx-3">
          {isLogged ? (
              <button
              className="buttonclick"
              id="loginbutton"
              onClick={(e) => handleLogout()}
              >
              LogOut
            </button>
          ) : (
              <button
              className="buttonclick"
              id="loginbutton"
              onClick={(e) => navigateToLogin(2)}
              >
              Login as a Medical Professional
            </button>
          )} 
        </div>
          </div>
      </div>
      <div className="buttonsmy">
      <div className="email-button">
        <a href="mailto:unstigma.in@gmail.com?bcc=varnika.chutani@gmail.com;bhullarekam04@gmail.com;abhaygaba27@gmail.com; ripudaman4827.be22@chitkara.edu.in ">
          Contact Us!
        </a>
      </div>
      <div className="email-button">
        <Link to="/feedback">Feedback</Link>
      </div>
      <div className="email-button">
        <Link to="/about">About Us</Link>
      </div>
     </div>
    </div>
  );
}
