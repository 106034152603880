import React from 'react'

export default function EduTemp(props) {
  return (
    <div>
       <div className="container my-3 d-flex justify-content-center">
       <div className="card w-75 mb-3">
    <div className="card-body">
      <h1 className="card-title">{props.title}</h1>
      <a href={props.link} className="btn btn-primary my-2"><h5>Explore</h5></a>
    </div>
  </div>
       </div>
    </div>
  )
}
