import React from 'react'
import Header from '../Header'
import ProffesionalHomePage from './ProffesionalHomePage'
import { Link } from 'react-router-dom'

export default function Professional() {
 
  return (
    <div>
      <Header/>
      {
        localStorage.getItem('isVerified')=="true"?<ProffesionalHomePage/>:<UnderVerification/>
      }
      <br></br>
    <div className="buttonsmy">
    <div className="email-button">
        <a href="mailto:unstigma.in@gmail.com?bcc=varnika.chutani@gmail.com;bhullarekam04@gmail.com;abhaygaba27@gmail.com; ripudaman4827.be22@chitkara.edu.in ">
          Contact Us!
        </a>
      </div>
      <div className="email-button">
        <Link to="/feedback">Feedback</Link>
      </div>
      <div className="email-button">
        <Link to="/about">About Us</Link>
      </div>
     </div>
    </div>
  )
}
function UnderVerification(){
  return(
    <h1 className=' container my-3 d-flex justify-content-center'>Your profile is under verification! We will get back to you soon through the provided email ID.</h1>
  )
}