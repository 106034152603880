import React, { useRef, useState } from "react";
import "./appointment.css";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  GetAllappointment,
  GetPrevioususerAppointment,
  GetuserAppointment,
  updateBookingId,
  updateReportedAppointment
} from "../firebase";
import Calender from "../Calender";

function Appointment() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [show, setShow] = useState(0);
  const [showId, setId] = useState(null);

  const [previous, setPrevious] = useState(0);
  const [report, setReport] = useState("");
  const refOpen = useRef(null);
  const refReportClose = useRef(null);

  const [timeSlots, setTimeSlots] = useState([]);
  const [appointement, setAppointement] = useState([]);
  const upcomingref = useRef(null);

  const handleClick = async () => {
    setSelectedTime(null);
    filter();
  };
  const handleReportChat = async () => 
  {
    await updateReportedAppointment(showId,report,localStorage.getItem('type'))
    alert("Chat Reported Succesfully")
 
    refReportClose.current.click();
  };

  //booking
  const book = async () => {
    //call firebase to update booking id
    const res = await updateBookingId(
      selectedTime.Ids[0],
      localStorage.getItem("userId")
    );
    if (res === 1) {
      alert("Booked SuccessFully");
      booked();
      setSelectedTime(null);
    } else {
      alert("Booking Fail", "Try Again");
    }
  };
  //get booked appointments
  const booked = async () => {
    await setShow(1);
    setPrevious(0);
    if (
      !localStorage.getItem(
        `bookedAppointmentof${localStorage.getItem("userDoctor")}`
      )
    ) {
      // // console.log("okk");
      const res = await GetuserAppointment(
        localStorage.getItem("userDoctor"),
        localStorage.getItem("userId")
      );
      setAppointement(res);
      localStorage.setItem(
        `bookedAppointmentof${localStorage.getItem("userDoctor")}`,
        JSON.stringify(res)
      );
    } else {
      setAppointement(
        JSON.parse(
          localStorage.getItem(
            `bookedAppointmentof${localStorage.getItem("userDoctor")}`
          )
        )
      );
    }
    setTimeout(() => {
      upcomingref.current.scrollIntoView({ behavior: "smooth" });
    }, 400);
  };
  const getprevious = async () => {
    await setShow(1);
    setPrevious(1);
    const res = await GetPrevioususerAppointment(
      localStorage.getItem("userDoctor"),
      localStorage.getItem("userId")
    );
    setAppointement(res);
    console.log("respone",appointement);
    setTimeout(() => {
      upcomingref.current.scrollIntoView({ behavior: "smooth" });
    }, 400);
  };
  const filter = async (today) => {
    let storedData = JSON.parse(
      localStorage.getItem(`${localStorage.getItem("userDoctor")}`)
    );
    let storedMap;
    if (storedData) {
      storedMap = new Map(Object.entries(storedData));
    }
    if (storedMap && storedMap.has(selectedDate)) {
      let dateValue = storedMap.get(selectedDate);
      setTimeSlots(dateValue);
    } else {
      // console.log("Call Me");
      const data = await GetAllappointment(
        localStorage.getItem("userDoctor"),
        today ? today : selectedDate
      );
      setTimeSlots(data);
      let map;
      if (storedData) {
        let storedData = JSON.parse(
          localStorage.getItem(`${localStorage.getItem("userDoctor")}`)
        );
        map = new Map(Object.entries(storedData));
      } else map = new Map();
      map.set(selectedDate, data);
      let mapObject = {};
      map.forEach((value, key) => {
        mapObject[key] = value;
      });
      localStorage.setItem(
        `${localStorage.getItem("userDoctor")}`,
        JSON.stringify(mapObject)
      );
    }
  };
  const getAvailableAppointement = async () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    await setSelectedDate(today);
    filter(today);
  };
const openModal=async(id)=>{
  refOpen.current.click();
  setId(id);

}
  useEffect(() => {
    getAvailableAppointement();
  }, []);
  useEffect(() => {
    handleClick();
  }, [selectedDate]);
  const heading = localStorage.getItem("topic");
  return (
    <>
    <button
        type="button"
        class="btn btn-primary"
        hidden={true}
        ref={refOpen}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >  
        Launch demo modal     
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Report Doctor   
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                ref={refReportClose}
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body reason1">
              <label className="mx-2" htmlFor="">
                Reason:
              </label>
              <input
                value={report}
                onChange={(e) => setReport(e.target.value)}
                type="text"
                className="awse"
                placeholder="Enter reason"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleReportChat}
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div className="App1">
        <div className="professionalTopic">
          <label style={{ alignText: "center", marginLeft: "30px" }}>
            {heading}
          </label>
        </div>
        <br />

        <div className="Headercss">
          <label className="professionalHeading">
            Appointment with a professional
          </label>
        </div>
        <div className="d-flex">
          <h2 className="bookHeading">Book an appointment</h2>
          <a style={{ textDecoration: "none", color: "black" }}>
            <button
              className="upcomingAppointment"
              onClick={() => {
                booked();
              }}
            >
              {" "}
              Upcoming Appointment{" "}
            </button>
            <button
              className="upcomingAppointment"
              onClick={() => {
                getprevious();
              }}
            >
              {" "}
              Previous Appointments{" "}
            </button>
          </a>
        </div>

        {/* Calendar for date selection */}
        {/* You can replace this with a date picker component of your choice */}
        <div className="input-class">
          <Calender setSelectedDate={setSelectedDate} />
        </div>

        {/* Time slots */}
        <div className="available">
          <h3 style={{ color: "black", marginLeft: "100px;" }}>
            {" "}
            Available Slots On {selectedDate} :
          </h3>
          <div className="time-slots">
            {timeSlots.length !== 0
              ? timeSlots.map((times, index) => (
                  <div
                    key={index}
                    className={`time-slot ${
                      selectedTime
                        ? selectedTime.time === times.time
                          ? "selected"
                          : ""
                        : ""
                    }`}
                    onClick={() => setSelectedTime(times)}
                  >
                    {times.time}
                  </div>
                ))
              : "No Slots Available"}
          </div>

          {/* Confirm and Go Back buttons */}
          <div className="d-flex ">
            <button
              className="confirm-button"
              onClick={book}
              disabled={!selectedDate || !selectedTime}
            >
              Confirm
            </button>
          </div>
        </div>
        {show ? (
          <div ref={upcomingref}>
            {previous ? (
              <h3>Previous Appointment: </h3>
            ) : (
              <h3>Upcoming Appointment: </h3>
            )}
            {console.log("appointement",appointement)}
            {appointement.length > 0
              ? appointement.map((arr, i) => {
                  return (
                    <div>
                      <div class="card my-3 mx-auto cards ">
                        <div class="card-header">{arr.Date}</div>
                        <div class="card-body ">
                          <h5 class="card-title">{arr.Time}</h5>
                          <div className="d-flex justify-content-center">
                      
                            {previous ? (
                              arr.isReported?"Already Reported!":(
                              <button
                                className="btn btn-success"
                                onClick={() => openModal(arr.id)}
                              >
                                Report
                              </button>
                              )
                              
                            ) : (
                              <>
                                {" "}
                                {arr.bookingAccepted === "" &&
                                arr.bookingDeclined === "" ? (
                                  <div style={{ color: "black" }}>
                                    Wait For your request to be Accepted.
                                  </div>
                                ) : arr.bookingAccepted === 1 ? (
                                  <>
                                    {arr.Link ? (
                                      <Link
                                        class="btn btn-warning xxl "
                                        to={arr.Link}
                                        target="blank"
                                      >
                                        Join{" "}
                                      </Link>
                                    ) : (
                                      <div style={{ color: "black" }}>
                                        You wil soon get the link
                                      </div>
                                    )}
                                  </>
                                ) : arr.bookingDeclined === 1 ? (
                                  <div style={{ color: "black" }}>
                                    Appointment Denied, Please Reschedule
                                  </div>
                                ) : (
                                  "hi"
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No Upcoming Appointment"}
          </div>
        ) : (
          ""
        )}
        <div></div>
      </div>
    </>
  );
}

export default Appointment;
