import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Protected(props) {
  const [show, setShow] = useState(0);
    const{Component,user,showAlert,userType}=props
    const navigate=useNavigate()
    useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        setShow(1);
      }, 300);
     if(!localStorage.getItem('userType')){
        navigate('/')
        showAlert("Session Expired, Login again", "warning")
     }
    else if(localStorage.getItem('userType')!=='1'&& !userType){
        navigate('/')
     }
    },[])
  return (
    <div>
      {show?<Component user={user} showAlert={showAlert}/>:""}
    </div>
  )
}