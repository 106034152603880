import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { storeuserdata } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import { auth } from "../firebase";
import bcrypt from 'bcryptjs';
import './SignUp.css'
import Header from "../Header";
import  emailjs from '@emailjs/browser'
import NavBar from "../Navbar";
export default function SignUp(props) {
  const [detailsuser, setdetailsuser] = useState({
    firstName: "",
    lastName: "",

    Dob: "",
    Age: "",
    phone: "",
    state: "",
    gender: "",
    email: "",
    password: "",
    confirmpassword: "",

  });
  const [isCheckBox1, setCheckBox1] = useState(false);
  const [isCheckBox2, setCheckBox2] = useState(false);

  const navigate = useNavigate();

  async function handledata(e) {

    const name = e.target.name;
    const value = e.target.value;

    setdetailsuser((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
    // console.log(detailsuser)
  }

  const createUser = async () => {
    // console.log("Button Clicked");
    if (detailsuser.password !== "" && detailsuser.password === detailsuser.confirmpassword) {
      await createUserWithEmailAndPassword(auth, detailsuser.email, detailsuser.password)
        .then(async (e) => {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              alert("Email verification link sent" );
            })
          alert("Registered Successfully", );
          detailsuser.password = await bcrypt.hash(detailsuser.password, 10); // 10 is the number of salt rounds
          detailsuser.confirmpassword = detailsuser.password
          storeuserdata(detailsuser);
          navigate("/login");
          // console.log("navigated")
        })
        .catch((err) => {
          if (detailsuser.password.length < 6)
            alert(
              "Password Length should be greater than or equal to atleast 6 characters",
            );

          if (err.message === "Firebase: Error (auth/email-already-in-use).")
            alert("Email already Used ");
          if (err.message === "Firebase: Error (auth/invalid-email).")
            alert("Invalid Email");
          // console.log("ok", err.message);
        });
    }
  };
  const handleCheckboxChange1 = (e) => {
    setCheckBox1(!isCheckBox1);
  };
  const handleCheckboxChange2 = (e) => {
    setCheckBox2(!isCheckBox2);
  };
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {

    // Calculate the date for 7 years ago
    const today = new Date();
    const minAge = 7;
    const maxYear = today.getFullYear() - minAge;
    const maxMonth = String(today.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based
    const maxDay = String(today.getDate()).padStart(2, '0');
    const maxDate = `${maxYear}-${maxMonth}-${maxDay}`;

    setMaxDate(maxDate);


  }, []);
 
  return (

    <>
      <NavBar/>
      <h4 style={{ color: 'black' }} className=" d-flex justify-content-center my-3">
        Create an unStigma Account
      </h4>
      <div className="d-flex justify-content-center" style={{ marginRight: "15px", marginLeft: '15px', paddingBottom: "25px" }}>

        <div className="doctorsignup " style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">First Name*</label>
            <div className="col-sm-10">

              {" "}
              <input
                name="firstName"
                onChange={handledata}
                type="text"
                className="form-control my-2"
                title="Enter Atleast 4 letters"

              />
            </div>
          </div>
          <div className="mb-3 row">
            <label className="col-sm-2 col-form-label">Last Name</label>
            <div className="col-sm-10">

              {" "}
              <input
                name="lastName"
                onChange={handledata}
                type="text"
                className="form-control my-2"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="exampleInputEmail1" className="col-sm-2 col-form-label">
              Email*
            </label>
            <div className="col-sm-10">
              {" "}
              <input
                name="email"
                onChange={handledata}

                type="email"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Password*
            </label>
            <div className="col-sm-10">
              <input
                name="password"
                onChange={handledata}

                type="password"
                className="form-control"
                id="exampleconfirmPassword1"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Confirm Password*
            </label>
            <div className="col-sm-10">
              <input
                name="confirmpassword"
                onChange={handledata}
                type="password"
                className="form-control"
                id="exampleconfirmPassword1"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              DOB*
            </label>
            <div className="col-sm-10">
              <input
                name="Dob"
                onChange={handledata}
                type="date"
                className="form-control"
                id="exampleconfirmPassword1"
                style={{ width: "100%", padding:'5px' }}
                max={maxDate}
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Age*

            </label>
            <div className="col-sm-10">
              <input
                name="Age"
                onChange={handledata}
                type="tel"
                className="form-control"
                id="exampleconfirmPassword1"
                style={{ width: '100%' }}
                maxLength="10"
                minLength="10"
              />
            </div>
          </div>


          <div className="d1">
            <label className="col-sm-2 col-form-label">Gender*</label>
            <div className="col-sm-10" style={{ display: 'inline-block' }}>

              <label className="d2" style={{ marginLeft: '10px' }} >
                <input
                  className="g1"
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={detailsuser.gender === "Male"}
                  onChange={handledata}
                />
                Male
              </label>

              <label className="d2" style={{ marginLeft: '10px' }}>
                <input
                  className="g1"
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={detailsuser.gender === "Female"}
                  onChange={handledata}
                />
                Female
              </label>
            </div>


          </div>

          <div className="d1">

            <label className="col-sm-2 col-form-label">Phone</label>
            <div className="col-sm-10" style={{ display: 'inline-block' }}>
              {" "}
              <input
                name="phone"

                pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                onChange={handledata}
                type="tel"  
                className="phonenu"
                title="Phone number should be 10 digits"
                maxLength="100"
                minLength="7"   
              />
            </div>

          </div>
          <div className="d1">

            <label className="col-sm-2 col-form-label">State/UT*</label>
            <div className="col-sm-10 mega" style={{ display: 'inline-block' }}>
              {" "}
              <select
                name="state"
                
                onChange={handledata}
                className="form-control"
              >
                <option value="">Select State</option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="West Bengal">West Bengal</option>
                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Delhi">Delhi</option>
                <option value="Puducherry">Puducherry</option>
              </select>

            </div>
          </div>
          
          <div className="mb-3 row">
            <div className="col-sm-10 offset-sm-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="readGuidelinesCheckbox"
                  onChange={handleCheckboxChange2}
                />
                <label className="form-check-label guidelines" style={{fontSize:'13px'}} htmlFor="readGuidelinesCheckbox">
                  {/* <Link to="/guidelinesuser" style={{ color: 'blue', fontSize: "12px" }}> */}
                    All the information provided above is <br />correct and I agree to account deletion<br /> in case contrary is found

                  {/* </Link> */}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-sm-10 offset-sm-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="readGuidelinesCheckbox"
                  onChange={handleCheckboxChange1}
                />
                <label className="form-check-label guidelines" style={{fontSize:'13px'}} htmlFor="readGuidelinesCheckbox">
                  <Link to="/guidelinesuser" style={{ color: 'blue', fontSize: "13px" }}>
                    I have read all the guidelines.

                  </Link>
                </label>
              </div>
            </div>
          </div>

          <div className="d-grid gap-2 ">
            {" "}
            <button
              disabled={detailsuser.confirmpassword === "" || detailsuser.password !== detailsuser.confirmpassword || !isCheckBox1 || !isCheckBox2 || detailsuser.Age === "" }
              type="button"
              className="btn btn-primary"
              onClick={(e) => createUser()}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
}