import React from "react";
import { GetQueryForuser, updateQueryState,updateReportedState } from "../firebase";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";



// const handleEndChat = (someid) => {
//   // console.log("hi",someid);
// }
const handleEndChat = async (someid) => {
  // // console.log("SignOut")
  await updateQueryState(someid);
  alert("Chat Ended Succesfully")
  window.location.reload(false)
};


export default function Modal2(props) {
  const navigate = useNavigate();
  const { queries } = props
  const { usertype } = props

  return (
    <div>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        hidden={true}
        data-bs-target="#exampleModal"
        ref={props.refOpen}
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header" style={{ backgroundColor: " rgb(125, 151, 209)" }}>
              <h1 class="modal-title fs-5" style={{ color: "rgb(30, 72, 135)", fontWeight: "bold" }} id="exampleModalLabel">
                {props.title ? props.title : "These are your Previous queries"}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                ref={props.refClose}
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style={{ backgroundColor: "azure" }}>
              <div class="row" >
                <div class="">
                  <div
                    data-bs-spy="scroll"
                    data-bs-target="#list-example"
                    data-bs-smooth-scroll="true"
                    class="scrollspy-example"
                    tabindex="0"
                  >
                    {
                      queries
                        .filter(query => query.isEndChat)
                        .map((query, i) => {
                          // { // console.log(query.id, query.isEndChat) }
                          return (

                            <div className="my-3 container">
                              <h4 style={{ display: 'flex', width: '100%', color: "#34568B", fontWeight: "bold" }} id="list-item-1">Query {i + 1}- {query.Question}</h4>
                              {/* <p> <h5 style={{ display: 'inline', fontWeight: "bold" }}>Description:</h5> {query.Description}</p> */}
                              <div class="text-center" >
                                <button className="btn btn-primary  " style={{ backgroundColor: '#34568B', color: 'white',fontSize:'15px',width:'120px' }} onClick={() => {
                                  navigate('/chatbox',
                                    {
                                      state: {
                                        id: query.id,
                                        isReported:query.isReported,
                                        isEndChat:query.isEndChat,
                                      },
                                    }
                                  ); props.refClose.current.click()
                                }}>Open Chat</button>
                              </div>
                            </div>
                          )
                        })
                    }
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                
                hidden={true}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
