import React from 'react';
import './Guidelines.css'; // Import the CSS file
import Header from '../Header';

const GuidelinesProf = () => {
  return (
    <>
    <Header/>
    <div className="guidelines-container">
      <h1 className="main-heading">Guidelines for Medical Professionals using unStigma</h1>

      <section className="guideline-section">
        <h3>Professional Conduct:</h3>
        <p>
          Medical professionals using the app are expected to adhere to the
          highest standards of professional conduct and ethics, maintaining a
          respectful and non-judgmental approach in all interactions.
        </p>
      </section>

      <section className="guideline-section">
        <h3>Current Licensing and Certification:</h3>
        <p>
          Medical graduates must be actively licensed and certified in their
          respective fields. All medical professionals should ensure that their
          credentials are up-to-date and adhere to the regulations of their
          licensing bodies.
        </p>
      </section>
      <section className="guideline-section">
        <h3>Evidence-Based Responses:</h3>
        <p>
        Medical professionals should provide accurate, evidence-based information in response to user queries or discussions, and clearly indicate if information provided is based on personal experience.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Patient Confidentiality:</h3>
        <p>
        Uphold patient confidentiality at all times. Do not disclose any personally identifiable information about patients, and avoid discussing specific cases in a way that could compromise privacy.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Respect for Diverse Perspectives:</h3>
        <p>
        Recognize and respect diverse perspectives on sexual and reproductive health. Avoid engaging in debates or discussions that may compromise the inclusive nature of the platform.


        </p>
      </section>

      {/* Add more sections for other guidelines... */}

      <h1 className="main-heading">Terms and Conditions for Medical Professionals:</h1>

      <section className="guideline-section">
        <h3>Professional Responsibility:</h3>
        <p>
          By using the app, medical professionals acknowledge their
          responsibility to provide accurate and up-to-date information, and to
          act in the best interests of users seeking guidance.
        </p>
      </section>

      <section className="guideline-section">
        <h3>Intellectual Property:</h3>
        <p>
          All contributions made by medical professionals, including responses,
          articles, or any other content, become the intellectual property of
          the app. The app may use, modify, or distribute these contributions
          as deemed appropriate.
        </p>
      </section>
      <section className="guideline-section">
        <h3>No Endorsement:</h3>
        <p>
        The app does not endorse any specific medical professional, practice, or treatment.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Liability Limitation:</h3>
        <p>
        The app owner is not liable for the actions or advice provided by medical professionals on the platform.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Termination of Access:</h3>
        <p>
        The app owner reserves the right to terminate a medical professional's access to the platform if they violate these terms and conditions, engage in unprofessional conduct, or provide inaccurate information.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Changes to Terms:</h3>
        <p>
        The app owner reserves the right to update or modify these terms and conditions. Medical professionals will be notified of any significant changes, and continued use of the app implies acceptance of the revised terms.

        </p>
      </section>
      <section className="guideline-section">
        <h3>Governing Law:</h3>
        <p>
        These terms and conditions are governed by the laws of the jurisdiction where the app owner is based. Any disputes arising from the use of the app will be resolved in accordance with these laws.
        </p>
      </section>

      {/* Add more sections for other terms and conditions... */}
    </div>
    </>
  );
};

export default GuidelinesProf;
