import React, { useState } from 'react';
import Style from './Header.css';
import { useNavigate } from 'react-router-dom';
import Login from './Login';
import { app, auth } from './firebase';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import HamburgerMenu from './HeaderButton';
import icons from '../Images/logo new.png';

const NavBar = (props) => {
  const navigate=useNavigate();
  return (
    <>

      <div className="header">
        
        <div className="header-content">
          <div className="heading">
          <p style={{color:'navy', fontWeight:'600'}}>unStigma</p>
          </div> 
          <img  src={icons} alt="" />
        </div> 
      </div> 
      <Outlet />
      
    </>
  );
};

export default NavBar;
