import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function ProtectLogin(props) {
  const [show, setShow] = useState(0);
    const{Component,showAlert}=props
    const navigate=useNavigate()
    useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTimeout(() => {
        setShow(1);
      }, 200);
     if(localStorage.getItem('userType')){
        navigate('/')
     }
    },[])
  return (
    <div>
      {show?<Component  showAlert={showAlert}/>:""}
    </div>
  )
}