import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { accept, deny, updateLink, CompleteAppointment,updateReportedAppointment } from "../firebase";
import "./Accordion.css";

export default function Accordion(props) {
  const { value, data, tag ,id} = props;
  const [updateLink, setupdateLink] = useState(0);
  const [acc, setacc] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [report, setReport] = useState("");
  const refOpen = useRef(null);
  const refReportClose = useRef(null);

  const menuRef = useRef(null);
  const AcceptAppoint = async () => {
    await accept(data.id);
    setacc(true);
    alert("Appointment Accepted , Successfully");
    data.bookingAccepted = "1";
  };
  const DeclineAppoint = async () => {
    await deny(data.id);
    setacc(false);

    alert("Appointment Declined , Successfully");
    window.location.reload();
  };
  const MarkComplete = async () => {
    await CompleteAppointment(data.id);
    console.log("completed", data);

    alert("Appointment Marked Completed");
  };

  const handleReportChat = async () => 
  {
    console.log("id",id);
    await updateReportedAppointment(id,report,localStorage.getItem('type'));

    alert("Chat Reported Succesfully")
 
    refReportClose.current.click();
  };

  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        hidden={true}
        ref={refOpen}
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >  
        Launch demo modal     
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Report User     
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                ref={refReportClose}
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body reason1">
              <label className="mx-2" htmlFor="">
                Reason:
              </label>
              <input
                value={report}
                onChange={(e) => setReport(e.target.value)}
                type="text"
                className="awse"
                placeholder="Enter reason"
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleReportChat}
              >
                Submit Report
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item " key={value}>
        <div class="accordion-header ahead">
          <div
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse${value}`}
            aria-expanded="false"
            aria-controls={`flush-collapse${value}`}
          >
            <div className="d-flex ">
              <h2 className="btn btn-outline-dark mx-1 abtn ">{value + 1} </h2>
              <h3 className="mx-3 adate" style={{ color: "black" }}>
                {data.Date}
              </h3>
              <h3 className="atime" style={{ color: "blue" }}>
                {data.Time}
              </h3>
            </div>
          </div>
        </div>

        <div
          id={`flush-collapse${value}`}
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          {tag === 3 ? (
            <div className="accordion-body">
             { console.log("reported",data)}
              {data.isReported? "Already Reported":
              <div>
                <button className="btn btn-success" onClick={()=>refOpen.current.click()}>
                  Report
                </button>
              </div>
              }
            </div>
          ) : (
            <div className="accordion-body">
              {data.bookingAccepted === "" && acc === false ? (
                <div>
                  <button className="btn btn-success" onClick={AcceptAppoint}>
                    Accept
                  </button>
                  <button
                    className="mx-4 btn btn-danger"
                    onClick={DeclineAppoint}
                  >
                    Decline
                  </button>
                </div>
              ) : data.Link === "" ? (
                <Addlink title={"Add Link"} documentid={data.id} />
              ) : (
                <>
                  <div className="links">
                    <Link class="btn btn-primary xxl " to={data.Link}>
                      Join{" "}
                    </Link>
                    <button
                      class=" mx-4 btn btn-primary xxl "
                      onClick={() => setupdateLink(!updateLink)}
                    >
                      Edit Link <i class="fa-solid fa-caret-down"></i>
                    </button>
                    <button
                      className="btn btn-primary xxl"
                      onClick={MarkComplete}
                    >
                      Mark as Completed
                    </button>

                    {updateLink ? (
                      <Addlink documentid={data.id} title={"Update Link"} />
                    ) : (
                      ""
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const Addlink = ({ documentid, title }) => {
  const [link, setLink] = useState("");
  const handleClick = async () => {
    // console.log("Link", link);
    //call function to update the link
    const res = await updateLink(documentid, link);
    if (res) {
      alert("Link SuccessFully Submitted");
      setLink("");
      window.location.reload();
    }
  };

  return (
    <>
      <div
        className="my-2"
        style={{ justifyContent: "left", textAlign: "left", display: "flex" }}
      >
        <label className="col-sm-2 col-form-label mx-2">{title}</label>
        <div className="col-sm-10">
          {" "}
          <input
            name="displayName"
            type="text"
            value={link}
            placeholder="Add the Link to your meeting"
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className="form-control"
          />
        </div>
      </div>
      <button className="btn btn-primary" onClick={handleClick}>
        Submit Link
      </button>
    </>
  );
};
