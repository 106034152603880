import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { storeStudentdata } from "../firebase";
import { useNavigate, Link } from "react-router-dom";
import { auth, storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import bcrypt from 'bcryptjs';
import './Medicalstyle.css'

export default function MedicalStudent(props) {
  const [displayName, setName] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [Dob, setDob] = useState("");
  const [age, setage] = useState("");
  const [gender, setgender] = useState("");
  const [phoneNumber, setphonenumber] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [resumeFile, setResumeFile] = useState(null);

  const [isResumeUploaded, setIsResumeUploaded] = useState(false);

  const [isCheckBox1, setCheckBox1] = useState(false);
  const [isCheckBox2, setCheckBox2] = useState(false);
  const [states, setST]=useState("")


  const navigate = useNavigate();
  var resumeUrl;

  const createUser = async () => {
    // // console.log("Button Clicked");
    if (password !== "" && password === confirm && selectedFile && Dob !== "") {

      await createUserWithEmailAndPassword(auth, email, password)
        .then((e) => {
          sendEmailVerification(auth.currentUser)
            .then(() => {
              alert("Email verification link sent to your mail");
        
            })
          const imgRef = ref(storage, `files/${v4()}`);
          const resumeRef = ref(storage, `resume/${v4()}`);
          uploadBytes(resumeRef, resumeFile)
            .then(async (snapshot) => {
              // // console.log('Image uploaded:', snapshot);
              resumeUrl = await getDownloadURL(resumeRef);
              setIsResumeUploaded(true);
              uploadBytes(imgRef, selectedFile)
              .then(async (snapshot) => {
                // // console.log('Image uploaded:', snapshot);
                const imageUrl = await getDownloadURL(imgRef);
                setIsImageUploaded(true);
                // password = await bcrypt.hash(password, 10);
                storeStudentdata({ displayName, email, Dob, age, phoneNumber, gender, qualification, states, imageUrl,resumeUrl });
                  alert("Registered Successfully");
                navigate("/login");
              })
              .catch((error) => {
                // console.error('Error uploading image:', error);
              });

            })
            .catch((error) => {
              console.error('Error uploading resume:', error);
            });
        

        })
        .catch((err) => {
          if (password.length < 6)
            alert(
              "Password Length should be greater than or equal to 6"
            );
          if (err.message === "Firebase: Error (auth/email-already-in-use).")
           alert("Email already Used, Use different email");
          if (err.message === "Firebase: Error (auth/invalid-email).")
            alert("Invalid Email");
          // // console.log("ok", err.message);
        });


    }
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsImageUploaded(true);
  };
  const handleResumeChange = (event) => {
    setResumeFile(event.target.files[0]);
    setIsResumeUploaded(true);
  };
  const handleUpload = async () => {
    if (!selectedFile) {
      // console.error("No file selected");


      return;
    }
    // // console.log("uploading")
    const imgRef = ref(storage, `files/${v4()}`);
    uploadBytes(imgRef, selectedFile)
      .then((snapshot) => {
        // // console.log('Image uploaded:', snapshot);

        setIsImageUploaded(true);
      })
      .catch((error) => {
        // console.error('Error uploading image:', error);
      });
  };
  const handleCheckboxChange1 = (e) => {

    setCheckBox1(!isCheckBox1);
    
  };
  const handleCheckboxChange2 = (e) => {

    setCheckBox2(!isCheckBox2);
    
  };
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    // Calculate the date for 7 years ago
    const today = new Date();
    const minAge = 7;
    const maxYear = today.getFullYear() - minAge;
    const maxMonth = String(today.getMonth() + 1).padStart(2, '0'); // JavaScript months are 0-based
    const maxDay = String(today.getDate()).padStart(2, '0');
    const maxDate = `${maxYear}-${maxMonth}-${maxDay}`;

    setMaxDate(maxDate);
  }, []);

  return (
    <>
      <h4 style={{ color: 'black' }} className=" d-flex justify-content-center my-3">
        Create a Medical Intern/Graduate unStigma Account
      </h4>


      <div className="d-flex justify-content-center" style={{ marginRight: "25px", paddingBottom: "25px" }}>

        <div className="doctorsignup " style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <div className="mb-3 row">
            <label className="col-sm-2 col-form-label my-2">Name*</label>
            <div className="col-sm-10 my-2">
              {" "}
              <input
                onChange={(e) => setName(e.target.value)}
                value={displayName}
                type="displayName"
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="exampleInputEmail1" className="col-sm-2 col-form-label">
              Email*
            </label>

            <div className="col-sm-10">
              {" "}
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
              />
            </div>
          </div>

          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              DOB*
            </label>
            <div className="col-sm-10">
              <input
                name="Dob"
                onChange={(e) => setDob(e.target.value)}

                type="date"
                className="form-control"
                id="exampleconfirmPassword1"
                style={{ width: "100%", padding:'5px' }}
                max={maxDate}

              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Age*

            </label>
            <div className="col-sm-10">
            <input
                name="Age"
                onChange={(e)=>setage(e.target.value)}
                type="tel"
                className="form-control"
                id="exampleconfirmPassword1"
                style={{ width: "100%" }}
                maxLength="120"
                minLength="10"
                
              />
            </div>
          </div>
          
          <div className="d1">
            <label className="col-sm-2 col-form-label">Gender*</label>
            <div className="col-sm-10" style={{display: 'inline-block'}}>

              <label className="d2"style={{marginLeft:'10px'}} >
                <input
                  className="g1"
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={gender === "Male"}
                  onChange={(e) => setgender(e.target.value)}
                />
                Male
              </label>

              <label className="d2" style={{marginLeft:'10px'}}>
                <input
                  className="g1"
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={gender === "Female"}
                  onChange={(e) => setgender(e.target.value)}
                />
                Female
              </label>
            </div>

          
          </div>

          <label   className="col-sm-2 col-form-label">Phone*</label>
          <div className="col-sm-10" style={{display:'inline-block'}}>
            <input
              name="phone"
              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
              onChange={(e) => setphonenumber(e.target.value)}
              type="tel"
              className="phonenu"
              title="Phone number should be 10 digits"
              maxLength="10"
              // style={{ marginLeft: '22px', width:'90%', display: 'inline-block', marginBottom:'10px'}}
              minLength="10"
            />


          </div>

          <div className="mb-3 row">
            <label htmlFor="exampleInputQualification1" className="col-sm-2 col-form-label">
              Qualification*
            </label>

            <div className="col-sm-10">
              {" "}
              <input
                onChange={(e) => setQualification(e.target.value)}
                value={qualification}
                type="qualification"
                className="form-control"
                id="exampleInputQualification1"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="exampleInputEmail1" className="col-sm-2 col-form-label">
              Upload Qualification Document*
            </label>

            <div className="col-sm-10">
              {" "}
              <input
                type="file"
                accept="image/*,application/pdf,application/vnd.ms-excel"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label htmlFor="exampleInputEmail1" className="col-sm-2 col-form-label">
              Upload Resume*
            </label>

            <div className="col-sm-10">
              {" "}
              <input
                type="file"
                accept="image/*,application/pdf,application/vnd.ms-excel"
                onChange={handleResumeChange}
                className="form-control"
              />

            </div>
          </div>

          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Password*
            </label>
            <div className="col-sm-10">
              <input
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type="password"
                className="form-control"
                id="exampleInputPassword1"
              />
            </div>
          </div>
          <div className="mb-3 row">
            <label
              htmlFor="exampleInputPassword1"
              className="col-sm-2 col-form-label"
            >
              Confirm Password*
            </label>
            <div className="col-sm-10">
              <input
                onChange={(e) => setConfirm(e.target.value)}
                value={confirm}
                type="password"
                className="form-control"
                id="exampleconfirmPassword1"
              />
            </div>
          </div>


          <label className="col-sm-2 col-form-label">State/UT*</label>
          <div className="col-sm-10 mega" style={{display: 'inline-block'}}>
            {" "}
            <select
              name="state"
              style={{ width:'85%', display: 'inline-block'}}
              onChange={(e) => setST(e.target.value)}
              className="form-control"
            >
              <option value="">Select State</option>
              <option value="Andhra Pradesh">Andhra Pradesh</option>
              <option value="Arunachal Pradesh">Arunachal Pradesh</option>
              <option value="Assam">Assam</option>
              <option value="Bihar">Bihar</option>
              <option value="Chhattisgarh">Chhattisgarh</option>
              <option value="Goa">Goa</option>
              <option value="Gujarat">Gujarat</option>
              <option value="Haryana">Haryana</option>
              <option value="Himachal Pradesh">Himachal Pradesh</option>
              <option value="Jharkhand">Jharkhand</option>
              <option value="Karnataka">Karnataka</option>
              <option value="Kerala">Kerala</option>
              <option value="Madhya Pradesh">Madhya Pradesh</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Manipur">Manipur</option>
              <option value="Meghalaya">Meghalaya</option>
              <option value="Mizoram">Mizoram</option>
              <option value="Nagaland">Nagaland</option>
              <option value="Odisha">Odisha</option>
              <option value="Punjab">Punjab</option>
              <option value="Rajasthan">Rajasthan</option>
              <option value="Sikkim">Sikkim</option>
              <option value="Tamil Nadu">Tamil Nadu</option>
              <option value="Telangana">Telangana</option>
              <option value="Tripura">Tripura</option>
              <option value="Uttar Pradesh">Uttar Pradesh</option>
              <option value="Uttarakhand">Uttarakhand</option>
              <option value="West Bengal">West Bengal</option>
              <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
              <option value="Chandigarh">Chandigarh</option>
              <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
              <option value="Lakshadweep">Lakshadweep</option>
              <option value="Delhi">Delhi</option>
              <option value="Puducherry">Puducherry</option>
            </select>

          </div>


          
          <div className="mb-3 row">
            <div className="col-sm-10 offset-sm-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="readGuidelinesCheckbox"
                  onChange={handleCheckboxChange2}
                />
                <label className="form-check-label" htmlFor="readGuidelinesCheckbox" style={{fontSize:'13px'}}>
                  {/* <Link to="/guidelinesprof" style={{ color: 'blue', fontSize: "10px" }}> */}
                  All the information provided above is <br />correct and I agree to account deletion<br /> in case contrary is found

                  {/* </Link> */}
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3 row">
            <div className="col-sm-10 offset-sm-2">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="readGuidelinesCheckbox"
                  onChange={handleCheckboxChange1}
                />
                <label className="form-check-label" htmlFor="readGuidelinesCheckbox" style={{fontSize:'13px'}}>
                  <Link to="/guidelinesprof" style={{ color: 'blue', fontSize: "13px" }}>
                    I have read all the guidelines. 
                    

                  </Link>
                </label>
              </div>
            </div>
          </div>

          <div className="d-grid gap-2">
            {" "}
            <button     
              disabled={!isImageUploaded || !isResumeUploaded|| !isCheckBox1 || !isCheckBox2 || qualification === ""|| gender===""  || states==="" || email ==="" ||displayName ==="" ||confirm === "" || Dob === "" || password !== confirm || age === "" || phoneNumber === ""}
              type="button"
              className="btn btn-primary"
              onClick={(e) => createUser()}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
