import React, { useState,useRef } from "react";
import { Link } from "react-router-dom";
import { accept, deny, updateLink } from "../firebase";

import { GetQueryForAllProf, GetQueryForProf, acceptQuery, GetPrevQueryForProf } from "../firebase";

import "./Accordion.css";

export default function AccordionForStudent(props) {
  const refOpen = useRef(null);
  const refClose = useRef(null);
 const[New,setNew]=useState(null);
  const { value, data } = props;
  console.log("DAtaataa", data)
  const [updateLink, setupdateLink] = useState(0);
  const [acc, setacc] = useState(false);
  const handleAccept = (query_id, prof_id) => {
    acceptQuery(query_id, prof_id);
    refClose.current.click();
alert("Query Accepted");

  };
  const AcceptAppoint =  async() => {
    props.ViewDescription(data);
  };
//   const getqueries = async () => {
//     // console.log("Getting Data");
//     const res = await GetQueryForAllProf();
//     setQueries(res);
//   };
//   const getAcceptedQueries = async () => {
//     // console.log("Getting Data");
//     const res = await GetQueryForProf(localStorage.getItem('userId'));
//     setAcceptedQueries(res);
//     // console.log(queries);
//   };
  const DeclineAppoint = async () => {
    await deny(data.id);
    setacc(false); 
    
    alert("Appointment Declined , Successfully");
    window.location.reload();
  };

  return (
    <>

      <div class="accordion-item " key={value}>
        <div class="accordion-header ahead">
          <div
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#flush-collapse${value}`}
            aria-expanded="false"
            aria-controls={`flush-collapse${value}`}
          >
            <div className="d-flex ">
              <h2 className="btn btn-outline-dark mx-1 abtn ">{value + 1} </h2>
              <h3 className="mx-3 adate" style={{ color: "black" }}>
              Query {value+ 1}:{data.Question}
              </h3>
              
            </div>
          </div>
        </div>

        <div
          id={`flush-collapse${value}`}
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
         {  
         <div>
            <button className="btn btn-success" onClick={AcceptAppoint}>
              View Description
            </button>
            

            </div>
            
         }
            
     
            
        </div>
          </div>
      </div>
    </>
  );
}

const Addlink = ({ documentid, title }) => {
  const [link, setLink] = useState("");
  const handleClick = async () => {
    // console.log("Link", link);
    //call function to update the link
    const res = await updateLink(documentid, link);
    if (res) {
      alert("Link SuccessFully Submitted");
      setLink("");
      window.location.reload();
    }
  };
  return (
    <>
      <div
        className="my-2"
        style={{ justifyContent: "left", textAlign: "left", display: "flex" }}
      >
        <label className="col-sm-2 col-form-label mx-2">{title}</label>
        <div className="col-sm-10">
          {" "}
          <input
            name="displayName"
            type="text"
            value={link}
            placeholder="Add the Link to your meeting"
            onChange={(e) => {
              setLink(e.target.value);
            }}
            className="form-control"
          />
        </div>
      </div>
      <button className="btn btn-primary" onClick={handleClick}>
        Submit Link
      </button>
    </>
  );
};
