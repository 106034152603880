import React, { useState } from "react";
import { auth, db, updateQuery } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import './Message.css';

const SendMessage = ({ scroll,query_id ,reported,endChat,requestReported}) => {
  const [message, setMessage] = useState("");

  const sendMessage = async (event) => {
    event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
     updateQuery(query_id,message,localStorage.getItem('userId'))
    setMessage("");
    scroll.current.scrollIntoView({ behavior: "smooth" });
  };
  // console.log("reported",reported)
  // console.log("endCHat",endChat)


  return (
    <>
    <form onSubmit={(event) => sendMessage(event)} className="send-message">
      <label htmlFor="messageInput" hidden>
        Enter Message
      </label>
     
      {reported === true || endChat===true ? (
  <input
    id="messageInput"
    name="messageInput"
    type="text"
    className="form-input__input"
    placeholder="You can't chat anymore"
    value={message}
    disabled={true}
    onChange={(e) => setMessage(e.target.value)}
  /> 
) : <><input
id="messageInput"
name="messageInput"
type="text"
className="form-input__input"
placeholder="type message..."
value={message}
onChange={(e) => setMessage(e.target.value)}
/>
 <button type="submit">Send</button>
 </> 
 }

     
      <br/>
    </form>
    </>
  );
};

export default SendMessage;