import React from 'react'
import './UpdateTime.css'
import { useState,useEffect } from 'react';
import {
    ekamdafunction
}from  '../../firebase'


function UpdateTime(props) {
  const [Dataid,setDataid]=useState("");
  const [DataTime,setDataTime]=useState([]);

  // console.log("UPDATE time",props.Data)

  
  useEffect(()=>{
    if(props.Data.length>0){
      setDataid(props.Data.id);
      setDataTime(props.Data.Time);
    }
  },[])
  //  const handleClick=async(id)=>{
  //    // console.log("Id",id);
  //    // ab delete krdo function bnke firebase ka id yeh hai aur document appointment
  //    await ekamdafunction(id);
  //    localStorage.removeItem('Times')
  //   //  props.refClose.current.click();
  //    alert("Time Slots Updated")
  //  }
  return (
    <div>
<button type="button" class="btn btn-primary" hidden={true} ref={props.refOpen} data-bs-toggle="modal" data-bs-target="#exampleModal">
 
</button>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Time Slots</h1>
        <button type="button" class="btn-close" ref={props.refClose} data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
       
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default UpdateTime
