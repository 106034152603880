import React from 'react'
import MakeSchedule from './MakeSchedule';
import { useRef, useState, useEffect } from "react";
import {
    makeSchedule,
    GetScheduledAppointment,


} from '../../firebase';
import EditSchedule from './EditSchedule';
import Accordion from "./../Accordion";
import Time from "./../Time";
import Calendar from "react-calendar";
import Calender from "../../Calender";
import './YourSchedlue.css'

export default function YourSchedule(props) {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState("12:00");
    
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    
    const handleTimeChange = (time) => {
        setSelectedTime(time);
    };
    const handleSchedule = async () => {
    
        const res = await makeSchedule(
            localStorage.getItem("userId"),
            localStorage.getItem("type"),
            { selectedDate, selectedTime }
            );
            alert("Appointment Scheduled");
            setSelectedDate(new Date());
            setSelectedTime("12:00");
        };
        const doctorid = localStorage.getItem("userId")
        const [scheduledappointments, setscheduledappointments] = useState([]);
    const getscheduledAppointment = async () => {

        const res = await GetScheduledAppointment(localStorage.getItem("userId"));
        setscheduledappointments(res);
    };
    function formatDate(inputDate) {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
      
        return `${day}/${month}/${year}`;
      }
    useEffect(() => {
        getscheduledAppointment();
    }, []);


    return (
        <div>

            <button type="button" class="btn btn-primary" ref={props.refopenyourschedule} data-bs-toggle="modal" hidden={true} data-bs-target="#exampleModalnew">
                Launch demo modal
            </button>

            <div class="modal fade" id="exampleModalnew" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Your Schedule</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" ref={props.refCloseyourSchedule} aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {scheduledappointments.map((app, i) => {
                                return (
                                    <>
                                    <div className='timestamp'>

                                        <div className='Dates'>
                                            {formatDate(app.Date)}
                                        </div>
                                        <div className='Time'>
                                            
                                            {
                                                app.Times.sort().map((item, i) => {
                                                    return (
                                                        <div className='slots'>
                                                            {item.time}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                            </div>
                                    </>

                                )

                            })
                            }

                        </div>
                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() => {props.refCloseyourSchedule.current.click();props.refEditOpen.current.click()}}
                            >
                                Edit
                            </button>
                            
                            <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() => { props.refCloseyourSchedule.current.click(); props.refOpenMake.current.click() }}
                            >

                                Make Schedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}


