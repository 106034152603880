import React, { useState } from 'react'
import { updateUserPassword } from './firebase';
import { useNavigate } from "react-router-dom";
import NavBar from './Navbar';

function Forget() {
    const[email,setEmail]=useState("");

    const navigate = useNavigate()
    const handleClick = async (e) => {
        e.preventDefault()

        try {
            if (!email) {
                document.getElementById('error').innerHTML = 'Please enter your email!'
            } else {
                await updateUserPassword(email)
                alert("Your Password has been changed!")
                navigate('/login')
            }
        } catch (error) {
            // // console.log('login with eamil error: ', error.message)
            document.getElementById('error').innerHTML = 'Sorry. Change password failed!'
        }
    }

    return (
        <>
        <NavBar/>
        <div className="d-grid gap-2 col-6 mx-auto my-auto" style={{maxWidth:'300px'}}>

        <div className="mb-3 my-3">
          <label htmlFor="exampleInputEmail1" className="form-label">Enter your Registered email</label>
          <input onChange={(e)=>{setEmail(e.target.value)}} value={email} type="email" className="form-control" id="exampleInputEmail1" />
        </div>
        <div className="d-grid gap-2"> <button  onClick={handleClick}    className="btn btn-primary">Reset</button></div>
      
              </div>
              </>
    )
}

export default Forget