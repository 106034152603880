import "./Appointmentprof.css";
import React, { useRef, useState } from "react";
import "./../queries.css";
import { useEffect } from "react";
import EditSchedule from "./EditSchedule";
import {
  GetQueryForAllProf,
  GetQueryForProf,
  acceptQuery,
  makeSchedule,
  GetConfirmedAppointment,
  GetScheduledAppointment,
  GetPreviousAppointment,
} from "../../firebase";
import MakeSchedule from "./MakeSchedule";
import { useNavigate } from "react-router-dom";
import Modal from "../../User/Modal";
import DatePicker from "react-datepicker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
// import { ScrollShadow } from "@nextui -org/react";
import Accordion from "./../Accordion";
import Time from "./../Time";
import Calendar from "react-calendar";
import Calender from "../../Calender";
import YourSchedule from "./YourSchedule";
import UpdateTime from "./UpdateTime";
import Header from "../../Header";

//npm install react-datetime-picker
function PreviousAppointments() {
  const navigate = useNavigate();
  const data = [1, 2, 3, 4, 5, 6, 7];

  const refEditOpen = useRef(null);
  const refEditClose = useRef(null);

  const refOpenUpdate = useRef(null);
  const refCloseUpdate = useRef(null);

  const refopenyourschedule = useRef(null);
  const refCloseyourSchedule = useRef(null);

  const refOpenMake = useRef(null);
  const refCloseMake = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [appointments, setappointments] = useState([]);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    setScrollPosition(scrollTop);
  };
  const getPreviousAppointments = async () => {
    const res = await GetPreviousAppointment(localStorage.getItem("userId"));

    console.log("data", res);
    sortData(res);
    //  // console.log("Result",res)
  };
  const doctorid = localStorage.getItem("userId");
  const sortData = (res) => {
    let sorted = res.sort((a, b) => {
      let dateA = new Date(`${a.Date} ${a.Time}`).getTime();
      let dateB = new Date(`${b.Date} ${b.Time}`).getTime();

      return dateA - dateB;
    });
    setappointments(sorted);
    // console.log("Sorted", sorted);
  };

  useEffect(() => {
    getPreviousAppointments();
  }, []);
  return (
    <div
                  className="scroll-container"
                  style={{ width: "600px", height: "330px", backgroundColor: '#0073a1', borderRadius: '8px' }}
                  onScroll={handleScroll}
                >
                  <div className="content">
                    <h1 style={{ color: "white" }}>
                      {" "}
                      Your Previous Appointments
                    </h1>
                    <div className="contain" style={{ border: '2px solid #0073a1' }}>
                      <div class="timelinecontainer" >
                        <div style={{ width: "100%", backgroundColor: '#0073a1', }}>
                          <div class="accordion " id="accordionFlushExample">
                            {appointments.length === 0 ? "No Comfirmed Appointment" : appointments.map((item, i) => {
                              // console.log("DAtaataa", item)
                              return <Accordion value={i} data={item} tag={3} id={item.id}/>;
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>  );
}

export default PreviousAppointments;
