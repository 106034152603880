import "./QueriesForStudents.css";
import React, { useRef, useState } from "react";
import "./queries.css";
import { useEffect } from "react";
import {
    GetQueryForAllProf,
    GetQueryForProf,
    acceptQuery,
    GetPrevQueryForProf,
    makeSchedule,
    GetConfirmedAppointment,
    GetScheduledAppointment,

} from "../firebase";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import TimePicker from "react-time-picker";
// import { ScrollShadow } from "@nextui -org/react";

import Header from "../Header";
import AccordionForStudent from "./AccordionForQueries";
import Modal from "../User/Modal";
import Modal2 from "./Modal2";

//npm install react-datetime-picker
function QueriesForStudent() {
    const navigate = useNavigate();
    const [queries, setQueries] = useState([]);
    const [modal, setModal] = useState("");
    const refOpen = useRef(null);
    const refOpenRead = useRef(null);

    const [acceptedqueries, setAcceptedQueries] = useState([]);
    const [previousqueries, setpreviousQueries] = useState([]);
    const [New, setNew] = useState([]);


    const refClose = useRef(null);
    const refCloseRead = useRef(null);

    
    const refOpenAccepted = useRef(null);
    const refOpenAccepted2 = useRef(null);
    const refCloseAccepted = useRef(null);
    const refCloseAccepted2 = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;
        setScrollPosition(scrollTop);
    };
    const handleAccept = (query_id, prof_id) => {
        acceptQuery(query_id, prof_id);
        getqueries();
        getAcceptedQueries();
        refClose.current.click();
    };
    const queryClick = (item) => {
        setModal(item);
        refOpen.current.click();
    };
    const getqueries = async () => {
        // console.log("Getting Data");
        const res = await GetQueryForAllProf();
        setQueries(res);
    };
    const getAcceptedQueries = async () => {
        // console.log("Getting Data");
        const res = await GetQueryForProf(localStorage.getItem('userId'));
        setAcceptedQueries(res);
        // console.log(queries);
    };
    const getPreviousQueries = async () => {
        // console.log("Getting Data");
        const res = await GetPrevQueryForProf(localStorage.getItem('userId'));
        setpreviousQueries(res);
        // console.log("previous", res);
    };
    useEffect(() => {
        getqueries();
        getAcceptedQueries();
        getPreviousQueries();
    }, []);
    const usertype = localStorage.getItem("type");
    const ViewDescription =  async(data) => {
        setNew(data);
        refOpenRead.current.click();
        };

    return (
        <>
            <button type="button" class="btn btn-primary" hidden={true} ref={refOpenRead} data-bs-toggle="modal" data-bs-target="#exampleModal1">
                Button
            </button>


            <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">  {New?.Question}</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {New?.Description}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" hidden={true} ref={refCloseRead} data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" onClick={() => handleAccept(New?.id, localStorage.getItem('userId'))} data-bs-dismiss="modal">Accept Query</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Ap">
                <div>
                    <div className="butn">
                        <button className="upcoming" onClick={() => { refOpenAccepted.current.click() }}>Ongoing Queries</button>
                        <button className="upcoming" onClick={() => { refOpenAccepted2.current.click(); getPreviousQueries(); }} >Previous Queries</button>
                        <Modal title={"Accepted Queries"} refOpen={refOpenAccepted} refClose={refCloseAccepted} queries={acceptedqueries} usertype={usertype} />
                        <Modal2 title={"Previous Queries"} refOpen={refOpenAccepted2} refClose={refCloseAccepted2} queries={previousqueries} usertype={usertype} />
                    </div>

                    <div className="appointments">
                        {/* <h1>Appointments</h1> */}

                        <div className="d-flex  justify-content-center">


                            <div
                                className="scroll-container"
                                style={{ width: "600px", height: "330px", backgroundColor: '#0073a1', borderRadius: '8px' }}
                                onScroll={handleScroll}
                            >
                                <div className="content">
                                    <h1 style={{ color: "white" }}>
                                        {" "}
                                        Queries For You
                                    </h1>
                                    <div className="contain" style={{ border: '2px solid #0073a1' }}>
                                        <div class="timelinecontainer" >
                                            <div style={{ width: "100%", backgroundColor: '#0073a1', }}>
                                                <div class="accordion " id="accordionFlushExample">
                                                    {console.log("queries", queries.length)}
                                                    {queries.length === 0 ? "No Queries as of Now!" : queries.map((item, i) => {
                                                        // console.log("DAtaataa", item)
                                                        return <AccordionForStudent value={i} data={item} ViewDescription={ViewDescription} />;
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>



            {/*        
          <div className="d-flex  justify-content-center">
            <div
              className="scroll-container  "
              style={{ width: "600px", height: "330px", backgroundColor: '#0073a1' }}
              onScroll={handleScroll}
            >
              <div className="content">
                <h1 style={{ color: "white" }}>
                  {" "}
                  Your upcoming Appointments
                </h1>
                <div className="contain" style={{ border: '2px solid #0073a1' }}>
                  <div class="timelinecontainer" >
                    <div style={{ width: "100%", backgroundColor: '#0073a1', }}>
                      <div class="accordion " id="accordionFlushExample">
                        {appointments.length === 0 ? "No Upcoming Appointment" : appointments.map((item, i) => {
                          // console.log("DAtaataa", item)
                          return <Accordion value={i} data={item} />;
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div> */}





        </>
    );
}

export default QueriesForStudent;



