import React,{useEffect} from "react";

import { Link, useNavigate } from "react-router-dom";
import "./Icons.css";
import Header from "../Header";
import Signup from "./SignUp";
import Contact from "../Contact";
import FeedbackPage from "../Feedback";
import puberty from "../../Images/puberty_icon-removebg-preview.png";
import Consent from "../../Images/consent_icon-removebg-preview.png";
import menstruation from "../../Images/menstration_icon-removebg-preview.png";
import preg from "../../Images/pregnancy_icon-removebg-preview.png";
import Contraceptive from "../../Images/contraception_icon-removebg-preview (1).png";
import std from "../../Images/std_icon-removebg-preview.png";
import intercourse from "../../Images/intercourse_icon-removebg-preview.png";
import {
  updateUserMail,
  
}from '../firebase'
import { GetMail } from "../firebase";
  function Icons(props) {
  const navigate = useNavigate();

//   const isEmailSent= async()=>{
//     // console.log("id",props.id)
//   const res = await GetMail(props.id); //falasae
//     // console.log("res",res);

 
    

// }
//   useEffect(() => {
//     isEmailSent()
//   },[])
  
  return (
    <div>
      <Header />
      <div className="login-content">
        <h1 style={{ textAlign: "center",fontWeight:'bolder',marginTop:'10px',fontSize:'22px' }} className="center-text">
          What do you want to ask about
        </h1>
        <div className="icon-container">

          <div className="icon" onClick={() => {navigate("/options");localStorage.setItem('topic','Puberty & Changes')}}>
            <div className="icon-content">
            
                <img src={puberty} alt="Icon 1" />
                <h4 className="title">Puberty & Changes</h4>
             
            </div>
          </div>
          <div className="icon" onClick={() => {navigate("/options");localStorage.setItem('topic','Menstruation & Myths')}}>
          <div className="icon-content">
            
            <img src={menstruation} alt="Icon 1" />
            <h4 className="title">Menstruation & Myths</h4>
         
        </div>
          </div>

          <div className="icon" onClick={() =>  {navigate("/options");localStorage.setItem('topic','Pregnancy & Abortion')}}>
          
            <div className="icon-content">
            
            <img src={preg} alt="Icon 1" />
            <h4 className="title">Pregnancy & Abortion</h4>
         
        </div>
          </div>

          <div className="icon" onClick={() =>  {navigate("/options");localStorage.setItem('topic','Intercourse')}}>
            <div className="icon-content">
          
                <img src={intercourse} alt="Icon 4" />
                <h4 className="title">Intercourse</h4>
          
            </div>
          </div>

          <div className="icon" onClick={() => {navigate("/options");localStorage.setItem('topic','STD & symptoms')}}>
            <div className="icon-content">
           
                <img src={std} alt="Icon 5" />
                <h4 className="title">STD & symptoms</h4>
            
            </div>
          </div>

          <div className="icon" onClick={() =>  {navigate("/options");localStorage.setItem('topic','Contraceptives')}}>
            <div className="icon-content">
            
                <img src={Contraceptive} alt="Icon 6" />
                <h4 className="title">Contraceptives</h4>
           
            </div>
          </div>

          <div className="icon" onClick={() =>  {navigate("/options");localStorage.setItem('topic','Consent & sexual health')}}>
            <div className="icon-content">
             
                <img src={Consent} alt="Icon 7" />
                <h4 className="title">Consent & sexual health</h4>
             
            </div>
          </div>
    
      </div>
     <div className="buttonsmy">
     <div className="email-button">
        <a href="mailto:unstigma.in@gmail.com?bcc=varnika.chutani@gmail.com;bhullarekam04@gmail.com;abhaygaba27@gmail.com; ripudaman4827.be22@chitkara.edu.in ">
          Contact Us!
        </a>
      </div>
      <div className="email-button">
        <Link to="/feedback">Feedback</Link>
      </div>
      <div className="email-button">
        <Link to="/about">About Us</Link>
      </div>
     </div>
    </div>
    </div>
  );
}

export default Icons;
