import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DoctorType.css'; // Import your CSS file
import Header from '../Header';

const DoctorType = () => {
  const [doctorType, setDoctorType] = React.useState("");
  const navigate = useNavigate();

  const handleDoctorType = (type) => {
    setDoctorType(type);
    localStorage.setItem("userDoctor", type);
    navigate('/appointment');
  }
  return (
    <>
    <Header/>
    <div className="doctor-type-container" style={{paddingBottom:'50px'}}>
      <h1 className='heading'>Which doctor suits you the best</h1>
      <div className="button-container">
     <div className='d-flex justify-content-center '>

          <button className="ekambutton" onClick={() => handleDoctorType("paediatrician")}>Paediatrician</button>
     </div>
    
      
     <div className='d-flex justify-content-center '>
          <button className="ekambutton" onClick={() => handleDoctorType("psychiatrist")}>Psychiatrist</button>
          </div>
    
          <div className='d-flex justify-content-center '>
        <button className="ekambutton" onClick={() => handleDoctorType("gynaecologist")}>Obstetrician/Gynaecologist</button>
        </div>
        
        <div className='d-flex justify-content-center '>
        <button className="ekambutton" onClick={() => handleDoctorType("dermatologist")}>Dermatologist</button>
        </div>
    
      </div>
    </div>
    </>
  );
}

export default DoctorType;
