import React from "react";
import './Contact.css';

function Contact(){
    return(
        <div className="email-button">
<a href="mailto:recipient@domain.com?cc=bhullarekam04@gmail.com">Email Us!</a>

        </div>
    );
}
export default Contact; 