import React from "react";
import { auth } from "../firebase";
import './Message.css';
//import { useAuthState } from "react-firebase-hooks/auth";

const Message = ({ message }) => {
  return (
    <div className={` my-4 chat-bubble  ${localStorage.getItem('userId') === message.answer_by? "right" : ""}`}>
      {/* <img
        className="chat-bubble__left"
        src={"https://gravatar.com/avatar/c54bf1f00be48437bf0f048db6b63533?s=400&d=monsterid&r=x"}
        alt="user avatar"
      /> */}
      <div className="chat-bubble__right ">
        {/* <p className="user-name">user</p> */}
        <p className="user-name"></p>
        <p className="user-message">{message.text}</p>
      </div>
    </div>
  );
};

export default Message;