import Login from "./components/Login";
import React, { useState } from "react";
import SignUp from "./components/User/SignUp";
import { onAuthStateChanged } from "firebase/auth";
import { auth, app, checkBlocked } from "./components/firebase";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Alert from "./components/Alert";
import { useEffect } from "react";
import Forget from "./components/Forget";
import Icons from "./components/User/Icons";
import SubscriptionCard from "./components/SubscriptionCard";
import DataState from "./context/dataState";
import SignUp2 from "./components/Prof/SelectSignUp";
import FeedbackPage from "./components/Feedback";
import IconClick from "./components/User/IconClick";
import HomeLogin from "./components/HomeLogin";
import LoadingScreen from "./components/Loading";
import Payment from "./components/Payment";
import Appointment from "./components/User/Appointment";
import Queries from "./components/Prof/ProffesionalHomePage";

import Mensturation from "./components/User/Mensturation";
import Faqs from "./components/User/Faqs";
import Educational from "./components/User/Educational";

import AskQuery from "./components/User/AskQuery";
import Chatbot from "./components/User/Chatbot";
import HomePage from "./components/HomePage";
import ChattingPage from "./components/User/ChattingPage";
import DoctorType from "./components/User/doctorType";
import SelectSignUp from "./components/Prof/SelectSignUp";
import GuidelinesProf from "./components/Prof/GuidelinesProf";
import GuidelinesUser from "./components/User/GuidelinesUser";


import AboutUS from "./components/AboutUS";
import Protected from "./Protected";
import ProtectLogin from "./ProtectLogin";
import ConfirmedAppointment from "./components/Prof/Appointement/confirmedAppointment";
import RUff from "./components/User/RUff";

function App() {
  const [userType, setuserType] = useState("");
  const [alert, setAlert] = useState(null);
  const [user, setUser] = useState("");
  const [isLogged, SetisLogged] = useState(false);
  const [isBlocked, SetisBlocked] = useState(false);
  const [show, setShow] = useState(0);
   
  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 4000);
  };
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        SetisLogged(1);
        // // console.log("User->",user);
        setUser(user);
        localStorage.setItem("userId", user.uid);
        
      }
       else {
        SetisLogged(0);
        // // console.log("LOGEEDOUT",isLogged);
      }
    });
    setTimeout(() => {
      setShow(1);
    },2000);
  }, [user]);
  // // console.log("Usertype",userType)
  const ok=async()=>{
    if(localStorage.getItem("email")&&localStorage.getItem("userType")==="1") {
      const res= await checkBlocked(localStorage.getItem("email"));
      // console.log('res.name',res )
          if(!res){
            // console.log("BLOCKING")
            SetisBlocked(true);
             localStorage.clear();
             window.location.reload();
          }
    }
  }
  useEffect(() => {
   ok();
  }, []);
  return (
    <>
    <div className="whole">
      <Router>
        <Alert alert={alert} />
        <div className="resizable-content">
          <DataState user={user}>
            <Routes>
              {show ? (
                <Route
                  exact
                  path="/"
                  element={
                  localStorage.getItem('userType')? (
                      <HomePage user={user} showAlert={showAlert} />     
                    ) : (
                      <HomeLogin 
                        setuserType={setuserType}
                        showAlert={showAlert}
                      />
                    )
                  }
                />
              ) : (
                ""
              )}
     
              <Route
                exact
                path="/login"
                element={<Login/>}
              />

              <Route
                exact
                path="/Signup"
                element={<SignUp/>}
              />
              
              <Route
                exact
                path="/Signup2"
                element={<SignUp2/>}
              />
                <Route exact path="/forget"   element={<ProtectLogin showAlert={showAlert} Component={Forget}/>} />
              <Route exact path="/about"  element={<AboutUS/>} />

            //All protected below

              <Route
                exact
                path="/subscribe"
                element={<Protected user={user} showAlert={showAlert} Component={SubscriptionCard}/>} 
              />

              <Route
                exact
                path="/feedback"
                element={<FeedbackPage/>} 
              />

              <Route exact path="/payment"  element={<Protected user={user}  showAlert={showAlert} Component={Payment} />} />
              <Route exact path="/appointment"  element={<Protected user={user} showAlert={showAlert} Component={Appointment} />} />
              <Route exact path="/queries"  element={<Protected user={user} showAlert={showAlert} Component={AskQuery}  />} />
              <Route exact path="/options"  element={<Protected user={user} showAlert={showAlert} Component={IconClick} />} />
              <Route exact path="/mensturation"  element={<Protected user={user} showAlert={showAlert} Component={Mensturation} />} />
              <Route exact path="/faqs"  element={<Protected user={user} showAlert={showAlert} Component={Faqs} />} />
              <Route exact path="/resources"  element={<Protected user={user} showAlert={showAlert} Component={Educational} />} />
              <Route exact path="/chatbot"  element={<Protected user={user} showAlert={showAlert} Component={Chatbot} />} />
              <Route exact path="/doctortype"  element={<Protected user={user} showAlert={showAlert} Component={DoctorType} />} />
             
              <Route
                exact
                path="/chatbox"
                element={<Protected user={user} userType={1} showAlert={showAlert} Component={ChattingPage}/>} 
              />
            <Route
            exact path="/guidelinesprof" element={<GuidelinesProf/>}
            />
             <Route
            exact path="/guidelinesuser" element={<GuidelinesUser/>}
            />
            <Route
            exact path="/confirmed" element={<ConfirmedAppointment/>}
            />
            <Route
            exact path="/ruff" element={<RUff/>}
            />
            </Routes>
          </DataState>
        </div>
      </Router>
      </div>
    </>
  );
}

export default App;
