import React from 'react'
import Icons from './User/Icons'
import Professional from './Prof/Professional'

export default function HomePage({showAlert,user}) {
    // // console.log(localStorage.getItem('userType'))
    // // console.log(user.uid);
  return (
    <div>
    {localStorage.getItem('userType')==="1"?<Icons id={user.uid} showAlert={showAlert}/>:localStorage.getItem('userType')==="2"?<Professional id={user.uid}/>:"Nothing to show"}   
    </div>
  )
}


