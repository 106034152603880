import React from 'react'
import IconClick from './IconClick'

export default function Mensturation() {
  return (
    <div>
      <IconClick title='Menstruation'/>
    </div>
  )
}
