import React, { useRef, useState } from "react";
import "./queries.css";
import { useEffect } from "react";
import { GetQueryForAllProf, GetQueryForProf, acceptQuery } from "../firebase";
import { useNavigate } from "react-router-dom";
import Modal from "../User/Modal";
import Appointmentprof from './Appointement/Appointmentprof'

import QueriesForStudent from "./QueriesForStudent";
function ProffesionalHomePage() {
  const [queries, setQueries] = useState([]);
  const refOpen = useRef(null);
  const refClose = useRef(null);
  const refOpenAccepted = useRef(null);
  const refCloseAccepted = useRef(null);
  
  const navigate=useNavigate();
  const [acceptedqueries, setAcceptedQueries] = useState([]);
  //  const [confirmstate, setState] =  "stuent"
  const [confirmstate, setState] = localStorage.getItem('type')

  



    
  return (
   
<>

    {localStorage.getItem('type') === 'student' ? <QueriesForStudent/> : <Appointmentprof/> }
</>

  );
}

export default ProffesionalHomePage;
