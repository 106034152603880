import React, { useState } from 'react';
import './Feedback.css'; // Import your CSS file
import { storeFeedback } from './firebase';
import Header from './Header';



const FeedbackPage = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async() => {
    // Print the rating and feedback to the // console
    // // console.log('Rating:', rating);
    // // console.log('Feedback:', feedback);
    await storeFeedback(rating,feedback)
    setFeedback('')
    setRating(0)
    alert("Fedback Submitted")

  };

  return (
    <>
    <Header/>
   <div className='mycont'>
     <div className="feedback-container">
      <h2>Feedback</h2>
      <div className="rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${rating >= star ? 'active' : ''}`}
            onClick={() => handleStarClick(star)}
          >
            &#9733;
          </span>
        ))}
      </div>
      <div className="feedback-text">
        <textarea
          rows="4"
          placeholder="Leave your feedback here..."
          value={feedback}
          onChange={handleFeedbackChange}
        ></textarea>
      </div>
      <div className="submit-button">
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
 
   </div>
   </>
  );
};

export default FeedbackPage;
