import React, { useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';

import './IconClick.css';
import Header from '../Header';
const IconClick = (props) => {
  const navigate=useNavigate();
  useEffect(()=>{
    return ()=>{
      localStorage.removeItem('bookedAppointmentofpaediatrician')
      localStorage.removeItem('bookedAppointmentofpsychiatrist')
      localStorage.removeItem('bookedAppointmentofgynaecologist')
      localStorage.removeItem('bookedAppointmentofdermatologist')
      localStorage.removeItem(`${localStorage.getItem("userDoctor")}`);
    }
  },[])
  return (
    <>
    <Header/>
        <div className="menstruation-container">
      
      <div className="headermen">
        <h1>{localStorage.getItem('topic')}</h1>
      </div>
      <div className="content">
  <h3>Select the option best suitable</h3>
  <button className="logo-button" onClick={() => navigate('/faqs')}> FAQs </button>
  <button className="logo-button" onClick={() => navigate('/resources')}> Educational Resources</button>
  <button className="logo-button" onClick={() => navigate('/chatbot')}> AI Chatbot</button>
  <button className="logo-button" onClick={() => navigate('/queries')}> QueryBox</button>
  <button className="logo-button" onClick={() => navigate('/doctorType')}> Appointment with Doctor</button>
</div>

    </div>
    </>

  );
};

export default IconClick;
