
import React from 'react'
import StripeCheckout from 'react-stripe-checkout';
function Payment() {

  const onToken = (token)=>{
    // // console.log(token);
    alert(token.id);
  }
  
  return (
    <>
    <div className='stripe'>
    <StripeCheckout
        className="hloo"
        token={onToken}
        name="DEV DEAKIN PAYMENT"
        currency='Inr'
        amount="100.00"
        stripeKey="pk_test_51NxewHSHwAktsFMieecjKQH210HRJUGYvRO7LGMT2wDmjaaKFmGFw1j7qAvtaUtLVZhjQIx2uiAANk3tomUVmEOs00MqjxDnTE"
      />
      </div>
    </>
  )
}

export default Payment
