import React, { useEffect, useState } from "react";
import "./Chatbot.css";
import Header from "../Header";
import ReactLoader from "react-loader";

const Chatbot = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const iframe = document.getElementById("chatbotIframe");

    const handleLoad = () => {
      setLoading(false);
    };

    if (iframe) {
      iframe.addEventListener("load", handleLoad);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", handleLoad);
      }
    };
  }, []);

  return (
    <>
    <Header/>
      <div className="chatbot">
        {1 ? (
          <div className="spinner">
             
          <ReactLoader
            lines={13}
            length={10}
            width={5}
            radius={10}
            corners={1}
            rotate={0}
            direction={1}
            color="#000"
            speed={1}
            trail={60}
            shadow={false}
            hwaccel={false}
            scale={1.0}
            loadedClassName="loadedContent"
          />
        
          </div>
        ) : null}
<div className="chatframe">

        <iframe
          id="chatbotIframe"
          style={{ border: "none", display: loading ? "none" : "block" }}
          srcDoc="<body><script src='https://cdn.botpress.cloud/webchat/v0/inject.js'></script><script>window.botpressWebChat.init({ 'composerPlaceholder': 'Chat with unStigma',  'botName': 'unStigma', botId: '26a7c9d8-1475-44b4-92db-149f03d61a79', 'hostUrl': 'https://cdn.botpress.cloud/webchat/v0', 'messagingUrl': 'https://messaging.botpress.cloud', clientId: '26a7c9d8-1475-44b4-92db-149f03d61a79', 'enableConversationDeletion': true, 'className': 'webchatIframe', 'containerWidth': '100%25', 'layoutWidth': '100%25', 'hideWidget': true, 'showCloseButton': false, 'disableAnimations': true, 
          'closeOnEscape': false, 'showConversationsButton': false,
          'enableTranscriptDownload': false,
          'avatarUrl':'https://image.freepik.com/free-vector/doctor-avatar-design_1146-14.jpg',
             'useSessionStorage':'false', 
             'stylesheet':'https://webchat-styler-css.botpress.app/prod/2a26799e-5ef5-4780-9de7-bf4827ea9b8a/v90371/style.css' });
             window.botpressWebChat.onEvent(function () { window.botpressWebChat.sendEvent({ type: 'show' }) }, ['LIFECYCLE.LOADED']);</script></body>"
             width="100%"
          height="100%"
          ></iframe>
      </div>
          </div>
    </>
  );
};

export default Chatbot;
