import React, { useContext, useEffect, useState } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import './SubscriptionCard.css'
import { useNavigate } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { GetPlan, updateUser } from './firebase';
import dataContext from '../context/dataContext';
import Header from './Header';

// export default function SubscriptionCard({user}) {
//      const data=useContext(dataContext)
//      const navigate=useNavigate
//     const Payment=async(amount,PlanType)=>{
//       //   const stripe=await loadStripe('pk_test_51O0zR2SEj7me9Njx7AihBCtOwFXXwemM3tIpqwI19cjqyFZdXTHYndJgIP9tgqjnfiAKCWPLamO5MLEBRJStu4qI00v0CiuRAE')
//       //   const res = await fetch('http://localhost:8080/subscribe', {
//       //       method: 'POST',
//       //       headers: {
//       //           'Content-Type': 'application/json',
//       //       },
//       //       body: JSON.stringify({amount,PlanType,user,}),
//       //   });
  // await updateUser(user.email,PlanType); 
//       //   const session=await res.json();
//       //   stripe.redirectToCheckout({
//       //       sessionId:session.id
//       //   }).then((result) => {
//       //       if (result.error) {
//       //           var displayError = document.getElementById('error-message');
//       //           displayError.textContent = result.error.message;
//       //         }
//       //      // // console.log("Updating")
//       //    updateUser(user.email,PlanType); 
//       //     });
//       navigate('/payment')
//     }
//     const onToken = (token)=>{
//       // // console.log(token);
//       alert(token.id);
//     }
//   return (
//     <div>
// {/* <div className='sub'>
//  <h1 style={{textAlign:'center'}}>Upgrade Plans</h1>
//       <div className="container h-100">
//   <div className="row align-middle">
//     <div className="col-md-6 col-lg-4 column">
//       <div className="card gr-1">
//         <div className="txt">
//           <h1>Free</h1>
//           <p>View & Post Articles</p>
//           <p></p>
//         </div>
//         <div className="ico-card">
//         <i className="fa fa-rebel"></i>
//       </div>
//       </div>
//     </div>
//     <div className="col-md-6 col-lg-4 column" onClick={e=>Payment(10,'Premium')}>
//       <div className="card gr-2">
//         <div className="txt">
//           <h1>Premium</h1>
//           <p>View Articles</p>
//         </div>
//         <h1 style={{textAlign:'center'}} >$10/Month</h1>
      
//       <div className="ico-card">
//         <i className="fa fa-codepen"></i>
//       </div>
//       </div>
//     </div>
//     <div className="col-md-6 col-lg-4 column" onClick={e=>Payment(50,'Business')}>
//       <div className="card gr-3">
//         <div className="txt">
//           <h1>Business</h1>
//           <p>View & Post Articles</p>
//         </div>
//         <h1 style={{textAlign:'center'}}>$50/Month</h1>
//         <StripeCheckout
//         className="hloo"
//         token={onToken}
//         name="DEV DEAKIN PAYMENT"
//         currency='Inr'
//         amount="100.00"
//         stripeKey="pk_test_51NxewHSHwAktsFMieecjKQH210HRJUGYvRO7LGMT2wDmjaaKFmGFw1j7qAvtaUtLVZhjQIx2uiAANk3tomUVmEOs00MqjxDnTE"
//       />
//       <div className="ico-card">
//         <i className="fa fa-empire"></i>
//       </div>
//       </div>
//     </div>
//   </div>    
// </div>
//     </div> */}
//     <button onClick={()=>{navigate('/payment')}}></button>
//     </div>

    
//   )
// }

export default function SubscriptionCard({user}) {
  const data=useContext(dataContext)
  const navigate=useNavigate()
  const [PlanType,SetPlanType]=useState('')
  // // console.log("Paln",PlanType);
  const onToken = async(token)=>{
          // // console.log(token);
          // // console.log("OkP",PlanType)
          alert(token.id);
          await updateUser(user.email,PlanType); 
        }
        const Payment=()=>{
          navigate('/payment')
        }
  return (
    <div>
      <Header/>
      <h1>We are under beta testing so our services are free of cost at the moment.</h1>
      {/* <h1 style={{textAlign:'center'}}>Upgrade Plans</h1>
         <div className="container h-100">
  <div className="row align-middle">
    <div className="col-md-6 col-lg-4 column">
      <div className="card gr-1">
        <div className="txt">
          <h1>Free</h1>
          <p>Free download of the app,access to</p>
          <p>pre-answered FAQs,educational</p>
          <p>resources and Ai chatbot</p>
        </div>
        <div className="ico-card">
        <i className="fa fa-rebel"></i>
      </div>
      </div>
    </div>
    <div className="col-md-6 col-lg-4 column" onClick={()=>SetPlanType('Premium')}>
      <div className="card gr-2">
        <div className="txt">
        <h1>Basic Plan</h1>
          <p>Inclusive of 1 Free Plan with access</p>
          <p>to Query Box with medical</p>
          <p>studens,charged monthly</p>

        </div>
        <StripeCheckout
        className="hloo"
        token={onToken}
        name="DEV DEAKIN PAYMENT"
        currency='Inr'
        // amount="1000.00"
        stripeKey="pk_test_51NxewHSHwAktsFMieecjKQH210HRJUGYvRO7LGMT2wDmjaaKFmGFw1j7qAvtaUtLVZhjQIx2uiAANk3tomUVmEOs00MqjxDnTE"
      />
      
      <div className="ico-card">
        <i className="fa fa-codepen"></i>
      </div>
      </div>
    </div>
    <div className="col-md-6 col-lg-4 column" onClick={()=>SetPlanType('Business')} >
      <div className="card gr-3">
        <div className="txt">
          <h1>Appointment Fee</h1>
          <p>Inclusive of 1 appointment and 1</p>
          <p>follow up with medical</p>
          <p>professional,charged for every</p>
          <p>new appointment</p>
        </div>
        <div  onClick={()=>SetPlanType('Business')}>
    <StripeCheckout
        className="hloo"
        token={onToken}
        name="Business Plan PAYMENT"
        currency='Inr'
        // amount="5000.00"
        stripeKey="pk_test_51NxewHSHwAktsFMieecjKQH210HRJUGYvRO7LGMT2wDmjaaKFmGFw1j7qAvtaUtLVZhjQIx2uiAANk3tomUVmEOs00MqjxDnTE"
      />
      </div>
      <div className="ico-card">
        <i className="fa fa-empire"></i>
      </div>
      </div>
    </div>
  </div>    
</div> */}
    </div>
  )
}
