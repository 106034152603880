import { GetPlan } from '../components/firebase';
import DataContext from './dataContext';
import React, { useEffect, useState } from 'react';
function DataState(props) {
    const [plan,setPlan]=useState("");
    const updatePlan=async()=>{
      if(props.user.email){
        const res=await GetPlan(props.user.email)
        await setPlan(res)
      }
    else setPlan(Math.random())
      // console.log("plan",plan);
    }
    // useEffect(() => {
    //   updatePlan()
    // }, [plan]);
    const data={
        'Plan':plan,
    }
  return (
    <DataContext.Provider value={data}>
    {props.children}
</DataContext.Provider>
  )
}

export default DataState
