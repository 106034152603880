import React, { useEffect, useRef, useState } from "react";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "../firebase";
import Message from "./Message";
import SendMessage from "./SendMessage";
import Header from '../Header';

import './Message.css';
import { useLocation } from "react-router-dom";
import ChatEnd from "../ChatEnd";
const handleReport = () => {
  alert("dsfsdfdf");
}
const ChattingPage = (props) => {
  const [messages, setMessages] = useState([]);

  const topic = localStorage.getItem("topic");
  const scroll = useRef();
  const location = useLocation();
  // console.log('For id', location.state.id)
  useEffect(() => {
    scroll.current.scrollIntoView({ behavior: "smooth" });
  })

  useEffect(() => {

    const q = query(
      collection(db, "Query"),
      //orderBy("createdAt", "desc"),
      //limit(50)
    );

    const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
      let fetchedMessages = [];
      QuerySnapshot.forEach((doc) => {
        if (doc.id === location.state.id) fetchedMessages = doc.data().Answer
      });
      const sortedMessages = fetchedMessages.sort(
        (a, b) => a.createdAt - b.createdAt
      );
      // console.log("meessgaes", fetchedMessages)
      setMessages(sortedMessages);
    });
    return () => unsubscribe;
  }, []);


  return (
    <>
      <Header />
      <label className="chatHeading" style={{ fontSize: "25px" }}>{topic}</label>

      <div className='idk '>
        <div className="QueryBoxHeading " >
          <label style={{ fontSize: "30px" }}>Query Box</label>
        </div>
        <div className="myicon ">
          {!location.state.isEndChat ?
            <ChatEnd id={location.state.id} /> : ""
          }

        </div>
      </div>

      <main className="chat-box">
        <div className="messages-wrapper ">
          {messages?.map((message, i) => (
            <Message key={i} message={message} />
          ))}
        </div>

        {/* when a new message enters the chat, the screen scrolls down to the scroll div */}
        <span ref={scroll} style={{ color: 'white' }} >Scroll</span>
        <SendMessage scroll={scroll} query_id={location.state.id} reported={location.state.isReported} endChat={location.state.isEndChat} />
      </main>

    </>
  );
};

export default ChattingPage;
