import React,{useState} from 'react'



export default function ShowFaq(props) {
    let {title,allAnswer}=props
    return (
      
      <div className="card my-5" >
       
        <h3 className='mx-2'> </h3>
        <div style={{display:'flex',justifyContent:'flex-end',position:'absolute',right:'0'}}>
      
            
      </div>
    <h5 className="card-title">{title}  </h5>
  <div className="card-body d-flex justify-content-center" data-bs-toggle="collapse" aria-expanded="false" data-bs-target={`#i${props.index}`}>

  <i  style={{color:'black',fontSize:'60px'}}class="fa-solid fa-caret-down"></i>
  </div>

  <div id={`i${props.index}`}  className="accordion-collapse collapse">

      <div>
        <li className="list-group-item d-flex justify-content-between align-items-start">
    <div className="ms-2 me-auto">
      <div className="fw-bold">Description</div>
      {allAnswer}
    </div>
  </li>
    </div>

    </div>

</div>
      
    )
}
