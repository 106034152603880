import React, { useState } from 'react'
import {  sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import {  Verify, updateMail } from './firebase'
import { auth } from './firebase';
import { Link } from "react-router-dom";
import './Login.css'
import  emailjs from '@emailjs/browser'
import NavBar from './Navbar';
export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setmessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const sendMail = (Name) =>  
  {
    const  service_id= 'service_s0s7xfq'
    const  template_id= 'template_61us0gq'
    const  key= 'sWr5ioxsgBjtzE_Vv'
  var templateParams = {
    from_name: "unStigma",
    to_name: Name,
    to_email: email,
};

  emailjs.send(service_id, template_id, templateParams,key)
  .then(function(response) {
    
  }, function(error) {
   
  });


  };
  const LoginUser = async() => {
    if(!email|| !password)  setmessage("Fill all the Fields");
    else{
      const isExist= await Verify(email,localStorage.getItem('loginas'));
      // console.log('exist',isExist);
       if(isExist){
       signInWithEmailAndPassword(auth, email, password)
         .then((e) => {
           //console.log("Logged in Succesfully")
           if(!auth.currentUser.emailVerified){
           alert(`First verify the link sent to the ${email}`,"info");
            sendEmailVerification(auth.currentUser)
           }
           else{
           alert("Loggedin Successfully")
           if(!isExist.isWelcome){
            sendMail(isExist.name);
            updateMail(email,localStorage.getItem('loginas'));
           }
           localStorage.setItem('userType',localStorage.getItem('loginas'));
           localStorage.setItem('email',email);
           localStorage.setItem('type',isExist.type);
           localStorage.setItem('isVerified',isExist.isVerified);
           navigate('/');
           }
         })
         .catch((err) => {
           //console.log(err)
           setmessage("Invalid Email or password");
         })
       }
       else{
         setmessage("Invalid Email or password");
       }
    }

  }
  const handlePassword =() =>{
    setShowPassword(!showPassword);
  }
  const SignUp = () => {
    //console.log("check ", props.userType)

    if (localStorage.getItem('loginas') === '1') navigate('/Signup')
    else if (localStorage.getItem('loginas') === '2') navigate('/Signup2')

  }

  return (
 <div style={{paddingBottom:'50px'}}>
 <NavBar/>
<p className='mt-4'  style={{textAlign:'center',fontSize:'30px',fontFamily:"monospace",color:'rgb(184, 98, 238)'}}>{localStorage.getItem('loginas') === '1'?'Login as a User':"Login as a Medical Professional"}</p>
<div
        className="container mt-3 mb-3"
        style={{ border: "1px solid grey", borderRadius:'10px', padding: "30px", backgroundColor:'rgb(115, 164, 228)', maxWidth: "350px" }}
      >
        <div style={{ textAlign: "center", marginBottom: "40px",fontSize:'50px',color:'darkblue',fontWeight:'bolder' }}>
         unStigma
        </div>

<div
          className="container  justify-content-center"
          style={{ maxWidth: "400px", }}
        >
          <div style={{ textAlign: "center" }}>
          <p className="text-danger">{message}</p>
          </div>
          <div style={{ marginBottom: "15px", position: "relative" }}>
            <label
              htmlFor="email"
              style={{
                fontSize: "12px",
                position: "absolute",
                top: "3px",
                left: "10px",
                background: "",
                color: "gray",
                padding: "0 5px",
                zIndex: 1,
              }}
            >
             email address
            </label>

            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => { setEmail(e.target.value); setmessage("") }}
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                paddingTop: "20px",
                paddingLeft: "15px",
              }}
            />
          </div>

          <div style={{ marginBottom: "1px", position: "relative" }}>
            <label
              htmlFor="password"
              style={{
                fontSize: "12px",
                color: "gray",
                position: "absolute",
                top: "3px",
                left: "10px",
                background: "",
                padding: "0 5px",
                zIndex: 1,
              }}
            >
              Password
            </label>

            <input
              type={showPassword ? "text" : "password"}
              minLength={5}
              id="password"
              name="password"
              value={password}
              onChange={(e) => { setPassword(e.target.value); setmessage("") }}
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxSizing: "border-box",
                paddingTop: "15px",
                paddingLeft: "15px",
              }}
            />

            <button
              type="button"
              onClick={handlePassword}
              style={{
                position: "absolute",
                top: "50%",
                right: "5px",
                transform: "translateY(-50%)",
                fontWeight: "bold",
                background: "none",
                border: "none",
                cursor: "pointer",
                zIndex: 2,
                color:'black'
                ,backgroundColor:'white'
              }}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
       
          <div style={{ textAlign: "left" }}>
            <Link to="/forget" style={{ color: "darkblue", textDecoration: "none" }}>
              Forgotten your password?
            </Link>
          </div>
          
          <button
            type="submit"
            className="btn btn-primary btn-lg btn-block mt-4"
            style={{ width: "100%" }}
            onClick={LoginUser}
          >
            Log In
          </button>
          <button
            className="btn btn-primary btn-lg btn-block my-4"
            style={{ width: "100%" }}
            onClick={SignUp}
          >
           SignUp
          </button>
         
        </div>
        </div>

</div>
  )
}