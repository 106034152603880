import React, { useState } from "react";
import SignUp2 from "./MedicalStudent";
import MedicalDoctor from "./MedicalDoctor";
import Header from "../Header";
import NavBar from "../Navbar";

export default function SelectSignUp({showAlert}) {
  const [btn,setBtn]=useState(1)

  return (
    <>
    <NavBar/>
    <div style={{marginTop:'10px',paddingBottom:'20px',marginLeft:'40px'}}>
   
    <ul class="nav nav-pills mb-3 "  id="pills-tab" role="tablist">
  <li class="nav-item" role="presentation">
    <button class={` btn btn-${btn===1?'primary':'outline-dark'} btn-lg my-2 `} onClick={()=>setBtn(1)} >Medical Intern/Graduate</button>
  </li>
  <li class="nav-item" role="presentation">
    <button class={`btn btn-${btn===2?'primary':'outline-dark'} btn-lg mx-1 my-2  `} onClick={()=>setBtn(2)} >Medical Specialist</button>
  </li>
 </ul>

 {
  btn===1?<SignUp2 showAlert={showAlert}/>:btn===2?<MedicalDoctor showAlert={showAlert}/>:'nothing'
 }

 </div>
 </>
  );
}
