import React, { useState, useEffect } from 'react';
import './Time.css';

const CustomTimePicker = (props) => {
  const [hours, setHours] = useState(12);
  const [minutes, setMinutes] = useState('0');
  const [period, setPeriod] = useState('PM');
  // props.setSelectedTime(`${hours}:${minutes} `);

  const handleTimeChange = () => {
    if ((hours >= 0 && hours <= 23) && (minutes >= 0 && minutes <= 59)) {
      if (hours <= 9 && hours[0] !== '0') {
        if (minutes <= 9 && minutes[0] !== '0') {
          if (hours === "0") {
            props.setSelectedTime(`0${hours}:0${minutes} `);
            props.setedit(`0${hours}:0${minutes} `);
          }
          props.setSelectedTime(`0${hours}:0${minutes} `);
          props.setedit(`0${hours}:0${minutes} `);

        }
        else {
          if (minutes === "0") {
            props.setSelectedTime(`0${hours}:0${minutes} `);
            props.setedit(`0${hours}:0${minutes} `);

          }
          else {
            props.setSelectedTime(`0${hours}:${minutes} `);
            props.setedit(`0${hours}:${minutes} `);

          }
        }

      }
      else {
        if (minutes <= 9 && minutes[0] !== '0') {
          if (hours === "0") {
            props.setSelectedTime(`0${hours}:0${minutes} `);
            props.setedit(`0${hours}:0${minutes} `);
          }
          else {

            props.setSelectedTime(`${hours}:0${minutes} `);
            props.setedit(`${hours}:0${minutes} `);
          }

        }
        else {
          if (minutes === "0") {
            if (hours === "0") {
              props.setSelectedTime(`0${hours}:0${minutes} `);
              props.setedit(`0${hours}:0${minutes} `);
            }
            else {
              props.setSelectedTime(`${hours}:0${minutes} `);
              props.setedit(`${hours}:0${minutes} `);
            }
          }
          else {
            if(hours==="0"){
              props.setSelectedTime(`0${hours}:${minutes} `);
              props.setedit(`0${hours}:${minutes} `);
             }
             else{
            props.setSelectedTime(`${hours}:${minutes} `);
            props.setedit(`${hours}:${minutes} `);
             }
          }

        }
      }

    }
    else {
      alert("The time slot selected is out of range")
    }
  };


  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);

  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(i);
    }
    return options;
  };

  return (
    <div>
      <div className='customtime' style={{ display: 'flex', alignItems: 'center' }}>
        <div className='timepicker'>
          Custom Time Picker (Enter in 24 Hour Format)
          <div className="customtimepicker">
            <label style={{ marginRight: '25px' }}>Hours:</label>
            <input value={hours} type="tel" onChange={(e) => { setHours(e.target.value); }}
              maxLength="2"
              minLength="0"
            
            // mixValue="0"
            />

            {/* {generateOptions(0, 23).map((hour) => (
            <option key={hour} value={hour}>
            {hour} 
              </option> */}
            {/* ))} */}

          </div>
          <div className="customtimepicker">
            <label>Minutes:</label>
            <input value={minutes} type="tel" onChange={(e) => {  { setMinutes(e.target.value); } }}
              maxLength="2"
              minLength="0"
          
            // mixValue="0"
            />
          </div>
          <button onClick={() => { handleTimeChange() }} className='add' >ADD</button>

        </div>

        {/* <div>
          <select value={period} onChange={handlePeriodChange}>
            <option value="AM">AM</option>
            <option value="PM">PM</option>
          </select>
        </div> */}
      </div>
      <div className='customtime'>
        Selected Times
        {
          props.allTimes.map((item, i) => {
            return (
              <button className='items'>{item}</button>
            )
          })
        }
      </div>
    </div>
  );
};

export default CustomTimePicker;