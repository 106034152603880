import React, { useState, useRef } from 'react';
import './HamburgerMenu.css';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';

const HamburgerMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleConfirm = () => {
    setIsConfirmOpen(!isConfirmOpen);
  };

  const handleSignOut = () => {
    navigate('/');
    window.location.reload();
    auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
  };

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setIsMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
    setIsConfirmOpen(false);
  };

  return (
    <div className="left-content" ref={menuRef}  onMouseLeave={handleMouseLeave}>
      <div className={`menu-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      {isMenuOpen && (
        <div className="menu-popup">
          <h1 style={{ fontSize: '30px', marginBottom: '20px' }}>Select Option</h1>
          {localStorage.getItem('userType') === '1' ? (
            <button className="ekambutton" onClick={() => navigate('/subscribe')}>
              Subscription
            </button>
          ) : (
            ''
          )}
          <br />
          <button className="ekambutton my-1" onClick={toggleConfirm}>
            Sign Out
          </button>
        </div>
      )}

      {isConfirmOpen && (
        <div className="menu-popup" style={{ paddingBottom: '35px' }}>
          <h1 style={{ fontSize: '30px', marginBottom: '40px' }}>Confirm to sign out?</h1>
          <button className="ekambutton mx-2" onClick={handleSignOut}>
            Yes
          </button>

          <button className="ekambutton" onClick={() => { toggleConfirm(); toggleMenu(); }}>
            No
          </button>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
