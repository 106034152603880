import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { GetScheduledAppointment , ekamdafunction } from '../../firebase';
import './EditSchedule.css';

export default function EditSchedule(props) {
  const [scheduledappointments, setscheduledappointments] = useState([]);
  const [Data, setData] = useState([]);
  const refOpen = useRef(null);
  const refClose = useRef(null);

  const getscheduledAppointment = async () => {
    const res = await GetScheduledAppointment(localStorage.getItem('userId'));
    setscheduledappointments(res);
    // console.log('appointments', res);
  };

  // console.log('EkamData', Data);

  const Click = (app) => {
    setData(app);
    refOpen.current.click();
  };
  const handleClick=async(id)=>{
       // ab delete krdo function bnke firebase ka id yeh hai aur document appointment
       await ekamdafunction(id);
       refClose.current.click();
       alert("Time Slots Updated")
       window.location.reload(false);
     }
  useEffect(() => {
    getscheduledAppointment();
  }, []);

  return (
    <div>
      <button
        type="button"
        hidden={true}
        className="btn btn-primary"
        ref={props.refOpen}
        data-bs-toggle="modal"
        data-bs-target="#exampleModaledit"
      >
        Launch demo modal
      </button>

      <div className="modal fade" id="exampleModaledit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Edit Your Schedule
              </h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" ref={props.refClose} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {scheduledappointments.map((app, i) => (
                <div key={i} className="my-3 dates">
                  {app.Date}
                  <i className="mx-2 fa-regular fa-pen-to-square" onClick={() => Click(app.Times)}></i>
                </div>
              ))}    
            </div>   
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>

      <div>
        <button
          type="button"
          className="btn btn-primary"
          hidden={true}
          ref={refOpen}
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        ></button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Time Slots
                </h1>
                <button type="button" className="btn-close" ref={refClose} data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                {Data.length > 0 &&
                  Data.map((item, i) => ( 
                    <div key={i} className="my-2 mx-3 dates">
                      {item.time}
                      <i onClick={() => handleClick(item.id)} className="mx-2 fa-solid fa-trash"></i>
                    </div>
                  ))}

              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
