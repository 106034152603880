import { initializeApp } from "firebase/app";
import {
  getFirestore,
  where,
  collection,
  addDoc,
  getDoc,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  initializeFirestore,
  serverTimestamp,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { query, orderBy, onSnapshot, limit } from "firebase/firestore";
const time = serverTimestamp;
const firebaseConfig = {
  apiKey: "AIzaSyCi2GiK0nvFN_1F-534bBSDvp1Ym9mf2ns",
  authDomain: "unstigma-3ba64.firebaseapp.com",
  projectId: "unstigma-3ba64",
  storageBucket: "unstigma-3ba64.appspot.com",
  messagingSenderId: "824976211215",
  appId: "1:824976211215:web:ebb308c0b16ce60849d27a",
  measurementId: "G-MSVY35QT5Q",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const storage = getStorage(app);
export const deleteHandler = async (id) => {
  //// console.log("Delete pressed")
  await deleteDoc(doc(db, "Questions", id));
};

//Get Plan Detail
export const GetPlan = async (email) => {
  const docRef = doc(db, "users", email);
  const docSnap = await getDoc(docRef);
  const val = docSnap.data() ? docSnap.data().Subscription.PlanType : "";
  return val ? val : "null";
};

export const updateUser = async (id, type) => {
  //// console.log("Making Subscription")
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const docRef = doc(db, "users", id);
  await updateDoc(docRef, {
    Subscription: { PlanDate: today, PlanType: type },
  });
};

//Forgot Password
export const updateUserPassword = async (email) => {
  sendPasswordResetEmail(auth, email).then(() => {
    //// console.log('sent')
  });
};
//Fetch the all FAQs
export const GetFaq = async () => {
  // //// console.log(`Get Data`);
  let obj = {};
  let arr = [];
  const querySnapshot = await getDocs(collection(db, "Faqs"));
  querySnapshot.forEach((doc) => {
    obj = {
      Question: doc.data().Question,
      Answer: doc.data().Answer,
    };
    //// console.log("Obj",obj)
    arr.push(obj);
  });
  return arr;
};
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});

//Store the user Info
export const storeuserdata = async (info) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const result = await setDoc(doc(db, "users", info.email), {
    info,
    type: "user",
    isWelcome: false,
    Subscription: { PlanDate: "", PlanType: "" },
    CreatedAt: new Date(),
  });
  //// console.log(result);
};
export const updateMail = async (email, dbName) => {
  //// console.log("Making Subscription")
  if (dbName === "1") {
    const docRef = doc(db, "users", email);
    await updateDoc(docRef, {
      isWelcome: true,
    });
  }
  if (dbName === "2") {
    const docRef = doc(db, "proffesionals", email);
    await updateDoc(docRef, {
      isWelcome: true,
    });
  }
};
//get ail service info
export const getMailInfo = async () => {
  const docRef = doc(db, "emailservice", "CjAVjBbfZaTmqwQ7p7Ye");
  const docSnap = await getDoc(docRef);
  return docSnap.data();
};
//Store the Professional Info
export const storeStudentdata = async (info) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const result = await setDoc(doc(db, "proffesionals", info.email), {
    info,
    isVerified: false,
    isWelcome: false,
    type: "student",
    CreatedAt: new Date(),
  });
  //// console.log(result);
};
//Store the Professional Info
export const storeDoctordata = async (info) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const result = await setDoc(doc(db, "proffesionals", info.email), {
    info,
    isVerified: false,
    isWelcome: false,
    type: "doctor",
    CreatedAt: new Date(),
  });
  //// console.log(result);
};

//Verify isProfessional or isUser
export const Verify = async (email, dbName) => {
  if (dbName === "1") {
    // console.log("inverify1", dbName);
    const docRef = doc(db, "users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.data())
      return {
        type: docSnap.data().type,
        isVerified: 1,
        isWelcome: docSnap.data().isWelcome,
        name: `${docSnap.data().info.firstName} ${
          docSnap.data().info.lastName
        }`,
      };
    // console.log("Doc", docSnap.data());
  }
  if (dbName === "2") {
    const docRef = doc(db, "proffesionals", email);
    const docSnap = await getDoc(docRef);
    // // console.log("Doc",docSnap.data());
    if (docSnap.data()) {
      // // console.log("data",docSnap.data())
      if (docSnap.data().type === "doctor")
        return {
          type: docSnap.data().info.doctorType,
          isVerified: docSnap.data().isVerified,
          isWelcome: docSnap.data().isWelcome,
          name: docSnap.data().info.displayName,
        };
      else
        return {
          type: docSnap.data().type,
          isVerified: docSnap.data().isVerified,
          isWelcome: docSnap.data().isWelcome,
          name: docSnap.data().info.displayName,
        };
    }
  } else {
    return 0;
  }
};
//Store The Feedbacks
export const storeFeedback = async (Rate, Feedback) => {
  //// console.log("Rate",Rate);

  const result = await setDoc(
    doc(db, "Feedback", localStorage.getItem("userId")),
    {
      Rate,
      Feedback,
    }
  );
  //// console.log(result);
};
const analytics = getAnalytics(app);
const auth = getAuth();
export { auth, app, db };

//Query database management
export const storeQuery = async (Title, Description, userId) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const result = await addDoc(collection(db, "Query"), {
    Title,
    Description,
    Answer: [{ text: Description, answer_by: userId, createdAt: today }],
    userId,
    proffesional_id: "",
    Date: today,
    isEndChat: false,
    isReported: false,
    reportedBy: "",
    reportReason: "",
  });
};

//User specific asked Query
export const GetQueryForuser = async (userid) => {
  let obj = {};
  let arr = [];
  const q = query(collection(db, "Query"), where("userId", "==", userid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().isEndChat === false) {
      obj = {
        Question: doc.data().Title,
        id: doc.id,
        Description: doc.data().Description,
        Answer: doc.data().Answer,
        isEndChat: doc.data().isEndChat,
        isReported: doc.data().isReported,
      };
      arr.push(obj);
    }
  });

  return arr;
};
export const GetPrevQueryForuser = async (userid) => {
  let obj = {};
  let arr = [];
  const q = query(collection(db, "Query"), where("userId", "==", userid));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().isEndChat === true || doc.data().isReported === true) {
      obj = {
        Question: doc.data().Title,
        id: doc.id,
        Description: doc.data().Description,
        Answer: doc.data().Answer,
        isEndChat: doc.data().isEndChat,
        isReported: doc.data().isReported,
      };
      arr.push(obj);
    }
  });

  return arr;
};

//Query for All Proffess
export const GetQueryForAllProf = async () => {
  let obj = {};
  let arr = [];

  const querySnapshot = await getDocs(collection(db, "Query"));
  querySnapshot.forEach((doc) => {
    if (doc.data().proffesional_id === "" && doc.data().isEndChat === false) {
      obj = {
        Question: doc.data().Title,
        id: doc.id,
        Description: doc.data().Description,
      };
      //// console.log("Obj",obj)
      arr.push(obj);
    }
  });

  return arr;
};

//Query for Aceppted Proff
export const GetQueryForProf = async (prof_id) => {
  let obj = {};
  let arr = [];
  const querySnapshot = await getDocs(collection(db, "Query"));
  querySnapshot.forEach((doc) => {
    if (doc.data().proffesional_id === prof_id) {
      obj = {
        Question: doc.data().Title,
        id: doc.id,
        Description: doc.data().Description,
        isEndChat: doc.data().isEndChat,
        isReported: doc.data().isReported,
      };
      arr.push(obj);
    }
  });
  return arr;
};

export const GetPrevQueryForProf = async (prof_id) => {
  let obj = {};
  let arr = [];
  const querySnapshot = await getDocs(collection(db, "Query"));
  querySnapshot.forEach((doc) => {
    if (doc.data().proffesional_id === prof_id) {
      if (doc.data().isEndChat === true || doc.data().isReported === true) {
        obj = {
          Question: doc.data().Title,
          id: doc.id,
          Description: doc.data().Description,
          isEndChat: doc.data().isEndChat,
          isReported: doc.data().isReported,
        };
        arr.push(obj);
        // console.log("object",obj);
      }
    }
  });
  return arr;
};

//Accept Query
export const acceptQuery = async (query_id, proffesional_id) => {
  // console.log("Called", query_id);
  const docRef=doc(db,"Query",query_id);
  const docSnap= await getDoc(docRef);
  if(docSnap.data().proffesional_id===""){
  await updateDoc(doc(db, "Query", query_id), {
    proffesional_id: proffesional_id,
  });
}
  //// console.log("Update Done")
};

//Update The Query Chat
export const updateQuery = async (query_id, text, answer_by) => {
  const docRef = doc(db, "Query", query_id);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const docSnap = await getDoc(docRef);
  const currAns = docSnap.data().Answer;
  currAns.push({ text, answer_by, createdAt: today });
  await updateDoc(doc(db, "Query", query_id), {
    Answer: currAns,
  });
  //// console.log("Update Done")
};
//end the chat
export const updateQueryState = async (documentid) => {
  const docRef = doc(db, "Query", documentid);
  const docSnap = await getDoc(docRef);

  await updateDoc(docRef, {
    isEndChat: true,
  });
  // console.log("ended", docSnap.data().isEndChat);
  //// console.log("Update Done")
};
export const updateReportedState = async (documentid, reason, reportedby) => {
  const docRef = doc(db, "Query", documentid);
  const docSnap = await getDoc(docRef);

  await updateDoc(docRef, {
    isReported: true,
    isEndChat: true,
    reportedBy: reportedby,
    reportReason: reason,
  });
  // console.log("reported", docSnap.data().isReported);
  //// console.log("Update Done")
};
export const sendReportedRequest = async (documentid) => {
  const docRef = doc(db, "Query", documentid);
  const docSnap = await getDoc(docRef);

  await updateDoc(docRef, {
    isEndChat: true,
  });
  // console.log("reported", docSnap.data().reportRequested);
  //// console.log("Update Done")
};
//Get Chat Messagess
export const getMessages = async (query_id) => {
  const q = query(
    collection(db, "Query")
    // orderBy("createdAt", "desc"),
    // limit(50)
  );

  const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
    let fetchedMessages = [];
    QuerySnapshot.forEach((doc) => {
      // // console.log("Doc",doc.id)
      if (doc.id === query_id) fetchedMessages = doc.data().Answer;
    });
    const sortedMessages = fetchedMessages.sort(
      (a, b) => a.createdAt - b.createdAt
    );
    // console.log(fetchedMessages);
    return fetchedMessages;
    // setMessages(sortedMessages);
  });

  //   const docRef = doc(db, "Query", query_id),
  // const docSnap = await getDoc(docRef);
  //    const currAns=docSnap.data().Answer
  //    return currAns
};

// cerate doctor schedule
export const makeSchedule = async (doc_id, doc_type, detail) => {
  const result = await addDoc(collection(db, "appointment"), {
    doc_id,
    doc_type,
    detail,
    booking_id: "",
    link: "",
    bookingAccepted: "",
    bookingDeclined: "",
    isCompleted: false,
  });
};

export const GetAllappointment = async (doctorType, date) => {
  let obj = {};
  let data = [];
  const q = query(
    collection(db, "appointment"),
    where("doc_type", "==", doctorType),
    where("booking_id", "==", ""),
    where("detail.selectedDate", "==", date)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // // console.log("New Data",doc.data() );

    obj = {
      Date: doc.data().detail.selectedDate,
      Time: doc.data().detail.selectedTime,
      id: doc.id,
    };
    data.push(obj);
  });
  //to store the count of avaibale time slots in map
  const map1 = new Map();
  data.map((item, i) => {
    let count = 1;
    let id = item.id;
    let newarr = [];
    if (map1.get(item.Time)) {
      count += map1.get(item.Time)[map1.get(item.Time).length - 1].count;
      newarr = map1.get(item.Time);
    }
    newarr.push({ count, id });
    map1.set(item.Time, newarr);
  });
  // to make a array of object containing different time slots and their corresponding all ids of doctor as array ids
  let arr = [];
  map1.forEach((value, key) => {
    let ids = [];
    value.map((val, i) => {
      ids.push(val.id);
    });
    arr.push({ time: key, Ids: ids });
  });
  arr.sort();
  // // console.log("Myarr",arr)
  return arr;
};
//Update The BookingId
export const updateBookingId = async (documentid, user_id) => {
  const docRef = doc(db, "appointment", documentid);
  const docSnap = await getDoc(docRef);

  if (docSnap.data().booking_id !== "") return 0;
  else {
    await updateDoc(docRef, {
      booking_id: user_id,
    });
    return 1;
  }
  //// console.log("Update Done")
};
//get booked appointeemnt for user
export const GetuserAppointment = async (doctorType, userid) => {
  let obj = {};
  let arr = [];
  const q = query(
    collection(db, "appointment"),
    where("doc_type", "==", doctorType),
    where("booking_id", "==", userid)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if(doc.data().isCompleted===false&&doc.data().isReported===false){
    obj = {
      Date: doc.data().detail.selectedDate,
      Time: doc.data().detail.selectedTime,
      Link: doc.data().link,
      bookingAccepted: doc.data().bookingAccepted,
      bookingDeclined: doc.data().bookingDeclined,
      id: doc.id,
    };
    arr.push(obj);
  }
  });
  return arr;
  // // console.log("insiieded")
};
export const GetPrevioususerAppointment = async (doctorType, userid) => {
  let obj = {};
  let arr = [];
  const q = query(
    collection(db, "appointment"),
    where("doc_type", "==", doctorType),
    where("booking_id", "==", userid),
    where("isCompleted","==",true)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    obj = {
      Date: doc.data().detail.selectedDate,
      Time: doc.data().detail.selectedTime,
      Link: doc.data().link,
      bookingAccepted: doc.data().bookingAccepted,
      bookingDeclined: doc.data().bookingDeclined,
      isReported:doc.data().isReported,
      id: doc.id,
    };
    arr.push(obj);
  });
  return arr;
  // // console.log("insiieded")
};
//get booked appointement for doctor
export const GetUpcomingAppointment = async (doctorid) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  let obj = {};
  let arr = [];
  // const q= query (collection(db, "appointment"),where("doc_id", "==", doctorid ),where("booking_id", "!=",""));
  const q = query(
    collection(db, "appointment"),
    where("doc_id", "==", doctorid),
    where("bookingDeclined", "==", ""),
    where("bookingAccepted", "==", "")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (
      doc.data().booking_id !== "" &&
      doc.data().detail.selectedDate >= today
    ) {
      obj = {
        Date: doc.data().detail.selectedDate,
        Time: doc.data().detail.selectedTime,
        Link: doc.data().link,
        bookingAccepted: doc.data().bookingAccepted,
        bookingDeclined: doc.data().bookingDeclined,
        id: doc.id,
      };
      arr.push(obj);
    }
  });
  return arr;
  // // console.log("insiieded")
};
//get booked appointement for doctor
export const GetConfirmedAppointment = async (doctorid) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;

  let obj = {};
  let arr = [];
  // const q= query (collection(db, "appointment"),where("doc_id", "==", doctorid ),where("booking_id", "!=",""));
  const q = query(
    collection(db, "appointment"),
    where("doc_id", "==", doctorid),
    where("bookingDeclined", "==", "")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (
      doc.data().booking_id !== "" &&
      doc.data().bookingAccepted !== "" &&
      doc.data().detail.selectedDate >= today &&
      doc.data().isCompleted === false
    ) {
      obj = {
        Date: doc.data().detail.selectedDate,
        Time: doc.data().detail.selectedTime,
        Link: doc.data().link,
        bookingAccepted: doc.data().bookingAccepted,
        bookingDeclined: doc.data().bookingDeclined,
        id: doc.id,
      };
      arr.push(obj);
    }
  });
  return arr;
  // // console.log("insiieded")
};
//get scheduled appointement for doctor
export const GetScheduledAppointment = async (doctorid) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0");
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  let obj = {};
  let arr = [];
  const q = query(
    collection(db, "appointment"),
    where("doc_id", "==", doctorid),
    where("booking_id", "==", "")
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().detail.selectedDate >= today) {
      obj = {
        Date: doc.data().detail.selectedDate,
        Time: doc.data().detail.selectedTime,
        id: doc.id,
      };
      arr.push(obj);
    }
  });
  // // console.log("arr",arr);
  const map1 = new Map();
  arr.map((item, i) => {
    let time = item.Time;
    let id = item.id;
    if (map1.get(item.Date)) {
      let prev = map1.get(item.Date);
      prev.push({ time, id });
      map1.set(item.Date, prev);
    } else {
      map1.set(item.Date, [{ time, id }]);
    }
  });
  // console.log("Map", map1);
  //
  let newArr = [];
  map1.forEach((value, key) => {
    obj = {
      Date: key,
      Times: value,
    };
    newArr.push(obj);
  });
  // console.log("New ArAy", newArr);
  return newArr;
  //Done hogya ekam bhai isme date aur corresponding time ka array + id aygi

  // // console.log("insiieded")
};
export const CompleteAppointment = async (documentid) => {
  const docRef = doc(db, "appointment", documentid);
  await updateDoc(docRef, {
    isCompleted: true,
  });
};
export const GetPreviousAppointment = async (doctorid) => {
  let obj = {};
  let arr = [];
  const q = query(
    collection(db, "appointment"),
    where("doc_id", "==", doctorid),
    where("isCompleted", "==", true)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    {
  console.log("query",doc.data())

      obj = {
        Date: doc.data().detail.selectedDate,
        Time: doc.data().detail.selectedTime,
        Link: doc.data().link,
        bookingAccepted: doc.data().bookingAccepted,
        bookingDeclined: doc.data().bookingDeclined,
        isReported:doc.data().isReported,
        id: doc.id,
      };
      arr.push(obj);
    }
  });
  return arr;

};
export const updateReportedAppointment = async (documentid, reason, reportedby) => {
  const docRef = doc(db, "appointment", documentid);
  const docSnap = await getDoc(docRef);

  await updateDoc(docRef, {
    isReported: true,
    reportedBy: reportedby,
    reportReason: reason,
  });
  // console.log("reported", docSnap.data().isReported);
  //// console.log("Update Done")
};
//Accep the booking
export const accept = async (documentid) => {
  const docRef = doc(db, "appointment", documentid);
  await updateDoc(docRef, {
    bookingAccepted: 1,
  });
  return 1;

  //// console.log("Update Done")
};
//Deny the booking
export const deny = async (documentid) => {
  const docRef = doc(db, "appointment", documentid);
  await updateDoc(docRef, {
    bookingDeclined: 1,
  });
  return 1;

  //// console.log("Update Done")
};
//Update The Link
export const updateLink = async (documentid, link) => {
  const docRef = doc(db, "appointment", documentid);
  await updateDoc(docRef, {
    link: link,
  });
  return 1;

  //// console.log("Update Done")
};
export const ekamdafunction = async (documentId) => {
  try {
    // Get a reference to the document
    await deleteDoc(doc(db, "appointment", documentId));

    // Delete the document

    // console.log("Document successfully deleted!");
  } catch (error) {
    console.error("Error deleting document: ", error);
  }
};
export const ReportRequestsByUser = async (query) => {
  const result = await setDoc(doc(db, "UserReports", query.id), {
    query,
    isReported: false,
  });
  //// console.log(result);
};
export const GetEmailService = async (prof_id) => {
  let obj = {};
  let arr = [];
  const querySnapshot = await getDocs(collection(db, "emailService"));
  querySnapshot.forEach((doc) => {
    obj = {
      templateid: doc.data().templateid,
      serviceid: doc.serviceid,
      key: doc.data().key,
    };
    arr.push(obj);
  });
  return arr;
};
export const checkBlocked = async (email) => {
  {
    const docRef = doc(db, "users", email);
    const docSnap = await getDoc(docRef);
    if (docSnap.data()) return true;
    // console.log("Doc", docSnap.data());
    else {
      return false;
    }
  }
};
